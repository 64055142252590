import { isNil, isEmpty, pickBy } from 'lodash';

export const isValidItem = item => !isNil(item) && (!isEmpty(item) || typeof item !== 'object');

export function formatFinancingAttributes({ tradeIn, tradeInOwedAmount }) {
  return {
    financing: {
      tradeInValuation: {
        tradeIn,
        tradeInOwedAmount,
      },
    },
  };
}

export function formatLoanPreferences(loanLocalStorageData) {
  const { apr, downPayment, numberOfMonths, tradeIn, creditScore } = loanLocalStorageData || {};
  const tradeInValuation = tradeIn ? { tradeIn } : {};
  const loanPreferencesData = {
    financeRate: apr,
    downPayment,
    numberOfMonths,
    creditEstimate: creditScore,
  };
  const loanPreferences = pickBy(loanPreferencesData, isValidItem);

  return pickBy({ loanPreferences, tradeInValuation }, isValidItem);
}

export function formatLoanPreferencesFromUPS(loanUPSData, tradeInValuationData) {
  const { downPayment, financeRate, numberOfMonths, creditEstimate } = loanUPSData || {};
  const { tradeIn } = tradeInValuationData || {};
  return { downPayment, creditScore: creditEstimate, apr: financeRate, numberOfMonths, tradeIn };
}

export function formatLeasePreferences(leaseLocalStorageData) {
  const { annualMileage, downPayment, numberOfMonths, tradeIn, creditScore } = leaseLocalStorageData || {};
  const tradeInValuation = tradeIn ? { tradeIn } : {};
  const leasePreferencesData = { annualMileage, downPayment, numberOfMonths, creditEstimate: creditScore };
  const leasePreferences = pickBy(leasePreferencesData, isValidItem);

  return pickBy({ leasePreferences, tradeInValuation }, isValidItem);
}

export function formatLeasePreferencesFromUPS(leaseUPSData, tradeInValuationData) {
  const { annualMileage, downPayment, numberOfMonths, creditEstimate } = leaseUPSData || {};
  const { tradeIn } = tradeInValuationData || {};
  return { annualMileage, downPayment, numberOfMonths, creditScore: creditEstimate, tradeIn };
}

export function formatTaxesAndFeesZipPreferences(taxesAndFeesZipLocalStorageData) {
  if (!taxesAndFeesZipLocalStorageData) return {};
  return {
    location: {
      lastGeoLocation: {
        postalCode: taxesAndFeesZipLocalStorageData,
      },
    },
  };
}
