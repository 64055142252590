const DELIMITER = ' ';
const DEFAULT_VALUE = '';

/**
 * Splits a user name which comes as a string in the following format: `First Last`
 *  - Covers a case when last name contains more than one word
 *  - Covers no-value and null-value cases
 * @param {null|string} [userName]
 * @returns {{first: {string}, last: string}}
 */
export function splitUserName(userName) {
  const split = (userName || DEFAULT_VALUE).trim().split(DELIMITER);

  return {
    first: split.shift(),
    last: split.join(DELIMITER),
  };
}
