import { get } from 'lodash';

/**
 * Returns max cash value from terms range.
 * @param {object} incentive
 * @returns {number}
 */
export const getCashByTermsRange = incentive =>
  !incentive.cash && incentive?.incentiveTermRange?.length
    ? Math.max(...incentive.incentiveTermRange.map(term => get(term, 'customerCash', 0)))
    : incentive.cash;
