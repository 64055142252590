import { merge, pickBy, isEmpty } from 'lodash';
import { STORAGE_KEYS } from 'site-modules/shared/constants/car-buying';
import { CalculatorsModel, CALCULATORS_PATHS } from 'client/data/models/calculators';
import { getAppraisalData } from 'site-modules/shared/utils/appraiser/vehicle-utils';
import { formatVehicleAttributes } from './vehicles';
import { formatMergedAttributes, formatMergedSaveSearchAttributes } from './merged-attributes';
import { formatDealAttributes } from './deals';
import {
  formatLoanPreferences,
  formatLoanPreferencesFromUPS,
  formatLeasePreferences,
  formatLeasePreferencesFromUPS,
  formatTaxesAndFeesZipPreferences,
  isValidItem,
} from './financing';

/**
 * Prepares a bundle that contains all properties
 * needed to save a record about a vehicle you're interested in to the IDM
 * @param {Object} vehicle All vehicle properties
 * @param {Boolean} toDelete - is "Delete" request?
 * @returns {Object}
 */
export function getSaveVinAttributes(vehicle, toDelete, vehicleParams = {}) {
  const mergedAttributes = formatMergedAttributes(vehicle);
  const vinAttributes = formatVehicleAttributes({ inventory: vehicle }, vehicleParams);

  return toDelete ? vinAttributes : merge(vinAttributes, mergedAttributes);
}

/**
 * Prepares a bundle that contains all properties
 * needed to save a record about a deal you're interested in to the IDM
 * @param {Object} vehicle All vehicle properties
 * @param {Boolean} toDelete - is "Delete" request?
 * @returns {Object}
 */
export function getSaveDealAttributes(vehicle, toDelete, { dealDetails, vehicleParams, isPurchase }) {
  const vinAttributes = getSaveVinAttributes(vehicle, toDelete, vehicleParams);

  return {
    ...vinAttributes,
    ...formatDealAttributes(vehicle, dealDetails, isPurchase),
  };
}

/**
 * Prepares a bundle that contains all merged attributes
 * needed to save a record about a vehicle you're interested in to the IDM
 * @param {Object} vehicle All vehicle properties
 * @returns {Object}
 */
export function getMergedAttributes({ make, model, year, subModel, type, modelLinkCode }) {
  return formatMergedAttributes({
    type,
    vehicleInfo: {
      styleInfo: {
        make,
        model,
        year,
        subModels: [{ identifier: subModel }],
      },
    },
    modelLinkCode,
  });
}

/**
 * Prepares a bundle that contains all search properties
 * needed to save a record about a search you're interested in to the IDM
 * @param {Object} saveSearch All search properties
 * @param {Object} searchResults All vehicle properties
 * @param {String} modelLinkCode
 * @returns {Object}
 */
export function getSaveSearchAttributes(saveSearch, searchResults, modelLinkCode) {
  const mergedAttributes = formatMergedSaveSearchAttributes(searchResults, modelLinkCode);

  return {
    savedSearches: [saveSearch],
    ...mergedAttributes,
  };
}

export function getFinancingAttributes(storage) {
  if (!storage) return {};

  const mappedLoanData = formatLoanPreferences(storage.get(STORAGE_KEYS.LOAN_PREFERENCES));
  const mappedLeaseData = formatLeasePreferences(storage.get(STORAGE_KEYS.LEASE_PREFERENCES));
  const mappedTaxesAndFeesData = formatTaxesAndFeesZipPreferences(
    storage.get(STORAGE_KEYS.TAXES_AND_FEES_ZIP_PREFERENCES)
  );

  return pickBy({ financing: { ...mappedLoanData, ...mappedLeaseData }, person: mappedTaxesAndFeesData }, isValidItem);
}

export function setFinancingAttributes(storage, { financing = {}, vehicles = {} }, setModelValue) {
  if (storage && !isEmpty(financing)) {
    const { loanPreferences = {}, leasePreferences = {}, tradeInValuation = {} } = financing;
    const existingLoanData = storage.get(STORAGE_KEYS.LOAN_PREFERENCES);
    const existingLeaseData = storage.get(STORAGE_KEYS.LEASE_PREFERENCES);
    const { make, model, year } = getAppraisalData(vehicles) || {};
    const vehicleData = { make, model, year };

    if (isEmpty(existingLoanData)) {
      const formattedUPSData = formatLoanPreferencesFromUPS(loanPreferences, tradeInValuation);
      setModelValue(CALCULATORS_PATHS.CALCULATOR_LOAN_SELECTIONS, CalculatorsModel, {
        ...formattedUPSData,
        ...vehicleData,
      });
    }

    if (isEmpty(existingLeaseData)) {
      const formattedUPSData = formatLeasePreferencesFromUPS(leasePreferences, tradeInValuation);
      setModelValue(CALCULATORS_PATHS.CALCULATOR_LEASE_SELECTIONS, CalculatorsModel, {
        ...formattedUPSData,
        ...vehicleData,
      });
    }
  }
}
