import { signOut as firebaseSignOut } from 'site-modules/shared/components/profile/firebase-auth';
import {
  CLEAR_PROFILE_DATA,
  DELETE_PROFILE,
  SET_OPTIONS,
  SIGN_IN,
  SIGN_IN_FIREBASE,
  SIGN_OUT,
  TOGGLE_PROFILE_SCREEN,
} from './constants';

export function toggleProfileScreen(screen, options) {
  return {
    type: TOGGLE_PROFILE_SCREEN,
    screen,
    options,
  };
}

export function signOut() {
  firebaseSignOut();
  return {
    type: SIGN_OUT,
  };
}

export function deleteProfile() {
  firebaseSignOut();
  return {
    type: DELETE_PROFILE,
  };
}

export function clearProfileData() {
  return {
    type: CLEAR_PROFILE_DATA,
  };
}

export function signInFirebase() {
  return {
    type: SIGN_IN_FIREBASE,
  };
}

export function signIn(isAnonymousUser) {
  return {
    type: SIGN_IN,
    isAnonymousUser,
  };
}

export function setProfileOptions(options) {
  return {
    type: SET_OPTIONS,
    options,
  };
}
