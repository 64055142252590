import { isEmpty, sumBy } from 'lodash';

/**
 * Gets calculated price values.
 * @param {number} sellingPrice
 * @param {number} totalRebate
 * @param {number} msrp
 * @param {array} incentives
 * @param {boolean} isNonTMVDealer
 * @returns {{purchasePrice: number, marketAdjustment: number, purchasePriceBeforeRebates: number, purchasePriceAfterRebates: number}}
 */
export function getCalculatedPrices({
  sellingPrice = 0,
  totalRebate = 0,
  msrp = 0,
  incentives = [],
  isNonTMVDealer = false,
}) {
  const purchasePrice = sellingPrice - totalRebate;
  const marketAdjustment = msrp - sellingPrice;
  const incentivesAvailable = !isEmpty(incentives);
  const incentivesAmount = incentivesAvailable
    ? sumBy(incentives, ({ cash, amount, rebateAmount }) => (typeof cash === 'number' ? cash : amount || rebateAmount))
    : 0;
  const purchasePriceBeforeRebates = incentivesAvailable ? sellingPrice : purchasePrice;
  const purchasePriceAfterRebates = isNonTMVDealer ? msrp : purchasePriceBeforeRebates - incentivesAmount;

  return {
    purchasePrice,
    purchasePriceBeforeRebates,
    purchasePriceAfterRebates,
    marketAdjustment,
    incentivesAmount,
  };
}
