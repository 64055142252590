import { get, omitBy, isNil } from 'lodash';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { getSyncDate } from 'site-modules/shared/components/profile/idm/idm-utils';

/**
 * Formats the VIN object for the "vehicles.vins.<VIN>" for the insider payload
 * @param {Object} { inventory: {...} } or { dealer: {...}, vehicle: {...} }
 * @param {Object} vehicleParams
 */
export function formatVehiclesVinAttribute({ inventory, dealer, vehicle }, vehicleParams = {}) {
  const styleInfo = get(inventory, 'vehicleInfo.styleInfo') || vehicle;
  const dealerInfo = get(inventory, 'dealerInfo') || dealer;
  const ts = getSyncDate();

  const vin = {
    niceMake: makeNiceName(styleInfo.make),
    niceModel: makeNiceName(styleInfo.model),
    franchiseId: `${get(dealerInfo, 'franchiseId', '') || get(dealerInfo, 'id', '')}`,
    locationId: `${get(dealerInfo, 'rooftopId', '')}`, // Need to be a string
    year: parseInt(styleInfo.year, 10),
    lastViewedTs: ts,
    savedTs: ts,
    ...omitBy(vehicleParams, isNil),
  };

  if (styleInfo.styleId) {
    vin.styleId = parseInt(styleInfo.styleId, 10);
  }

  return vin;
}

/**
 * Forms vehicle attribute for insider payload.
 * @param {Object} vehicle (or inventory) { inventory: {...} }
 * @param {Object} vehicleParams
 */
export function formatVehicleAttributes(vehicle, vehicleParams) {
  return {
    vehicles: {
      vins: {
        [vehicle.inventory.vin]: formatVehiclesVinAttribute(vehicle, vehicleParams),
      },
    },
  };
}
