import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { PROFILE_TRACKING_CONSTANTS } from 'client/tracking/profile-tracking-constants';
import { SMART_SIGN_VALID_EMAIL_EVENT } from 'site-modules/shared/components/profile/smart-sign/helper';
import { get } from 'lodash';

export const fireAction = eventData =>
  EventToolbox.fireTrackAction({
    ads: {
      disable_refresh: true,
    },
    ...eventData,
  });

export const displayGoogleOneTap = (subActionName, value) => {
  const eventData = {
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
    action_name: TrackingConstant.ACTION_SHOW_CONTENT,
    subaction_name: subActionName,
    creative_id: 'google_one_tap',
    ...(value ? { value } : {}),
  };
  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

/**
 * Fire tracking on successful authentication through social buttons
 *
 * @param {Object} extendObj Tracking data
 */
export const successSocialAuthAction = (extendObj = {}) => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    action_name: TrackingConstant.ACTION_SIGN_IN,
    subaction_name: TrackingConstant.AUTHENTICATE_SOCIAL,
    creative_id: PROFILE_TRACKING_CONSTANTS.SIGN_IN_SOCIAL_CREATIVE_ID,
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_END, event_data: eventData });
};

/**
 * Fire tracking on successful sign up / sign in
 *
 * @param {String} authAction Auth action name in uppercase (ex. SIGN_UP. SIGN_IN)
 * @param {Object} extendObj Tracking data
 */
export const successAuthAction = (authAction, extendObj = {}) => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    action_name: TrackingConstant[`ACTION_${authAction}`],
    subaction_name: TrackingConstant[`SUCCESS_${authAction}`],
    creative_id: PROFILE_TRACKING_CONSTANTS[`${authAction}_DRAWER_CREATIVE_ID`],
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_END, event_data: eventData });
};

/**
 * Fire tracking on successful sign up / sign in
 *
 * @param {String} authAction Auth action name in uppercase (ex. SIGN_UP. SIGN_IN)
 * @param {Object} extendObj Tracking data
 */
export const saveModel = (authAction, extendObj = {}) => {
  const eventData = {
    action_name: TrackingConstant.SAVE_VEHICLE,
    subaction_name: TrackingConstant.SAVE_MODEL,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    creative_id: PROFILE_TRACKING_CONSTANTS[`${authAction}_DRAWER_CREATIVE_ID`],
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

/**
 * Fire tracking on progress auth action ( data-tracking-id does not suite because of onMouseDownEvent )
 *
 * @param {String} authAction Auth action name in uppercase (ex. SIGN_UP. SIGN_IN)
 * @param {Object} extendObj Tracking data
 */
export const progressAuthAction = (authAction, extendObj = {}) => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    action_name: TrackingConstant[`ACTION_${authAction}`],
    subaction_name: TrackingConstant[`SUBMIT_${authAction}`],
    creative_id: PROFILE_TRACKING_CONSTANTS[`${authAction}_DRAWER_CREATIVE_ID`],
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS, event_data: eventData });
};

/**
 * Fire tracking when user tries to complete auth action ( data-tracking-id does not suite because of onMouseDownEvent )
 *
 * @param {String} authAction Auth action name in uppercase (ex. SIGN_UP. SIGN_IN)
 * @param {Object} extendObj Tracking data
 */
export const completeAuthAction = (authAction, extendObj = {}) => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    action_name: TrackingConstant[`ACTION_${authAction}`],
    subaction_name: TrackingConstant[`AUTHENTICATE_${authAction}`],
    creative_id: PROFILE_TRACKING_CONSTANTS[`${authAction}_DRAWER_CREATIVE_ID`],
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

/**
 * When user successfully logs in through save search, fire save_search complete pixel.
 *
 * @param {Object} searchQuery
 * @param {Object} extendObj Tracking data
 */
export const authenticatedSaveSearchAction = (searchQuery = {}, extendObj = {}) => {
  const searchQueryValue = Object.keys(searchQuery)
    .map(key => `${key}:${searchQuery[key]}`)
    .join(',');

  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
    action_name: TrackingConstant.SAVE_VEHICLE,
    subaction_name: TrackingConstant.SAVE_SEARCH,
    creative_id: PROFILE_TRACKING_CONSTANTS.AUTHENTICATED_SAVE_SEARCH,
    value: searchQueryValue,
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

/**
 * When user successfully logs in through save vin, fire save_vin complete pixel.
 *
 * @param vin
 * @param {Object} extendObj Tracking data
 */
export const authenticatedSaveVinAction = (vin, extendObj = {}) => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
    action_name: TrackingConstant.SAVE_VEHICLE,
    subaction_name: TrackingConstant.SAVE_VIN,
    creative_id: PROFILE_TRACKING_CONSTANTS.AUTHENTICATED_SAVE_VIN,
    value: vin,
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

/**
 * When appraiser data is successfully saved.
 *
 * @param {Number} tradeIn
 * @param {Object} extendObj Tracking data
 */
export const authenticatedSaveAppraisalAction = (tradeIn, extendObj = {}) => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
    action_name: TrackingConstant.SAVE_VEHICLE,
    subaction_name: TrackingConstant.SAVE_APPRAISAL,
    creative_id: PROFILE_TRACKING_CONSTANTS.AUTHENTICATED_SAVE_APPRAISAL,
    value: tradeIn,
    ...extendObj,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

/**
 * When user tries to reset password entering new one
 *
 * @param subActionName
 * @private
 */
export const resetPasswordAction = subActionName => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
    action_name: TrackingConstant.ACTION_UPDATE_MEMBERSHIP,
    subaction_name: subActionName,
    creative_id: PROFILE_TRACKING_CONSTANTS.AUTH_ACTION_CREATIVE_ID,
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

export const resetPasswordSuccess = () => resetPasswordAction(TrackingConstant.RESET_PASSWORD_SUCCESS);

export const resetPasswordFailure = () => resetPasswordAction(TrackingConstant.RESET_PASSWORD_FAILURE);

export const navigationClose = creativeId => {
  const eventData = {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: 'click',
    action_name: TrackingConstant.ACTION_RESET_NAVIGATION,
    subaction_name: TrackingConstant.NAVIGATION_CLOSE,
    creative_id: creativeId,
  };
  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

export const authenticateSaveModalOpen = () => {
  const eventData = {
    action_name: TrackingConstant.ACTION_WIDGET_VIEW,
    action_cause: TrackingConstant.ACTION_CAUSE_DRAWER_OPEN,
    creative_id: 'authenticated-modal',
  };

  fireAction({ event_type: TrackingConstant.ACTION_WIDGET_VIEW, event_data: eventData });
};

export const emailLinkSentModalOpen = () => {
  const eventData = {
    action_name: TrackingConstant.ACTION_WIDGET_VIEW,
    action_cause: TrackingConstant.ACTION_CAUSE_DRAWER_OPEN,
    creative_id: 'email-link-sent',
  };

  fireAction({ event_type: TrackingConstant.ACTION_WIDGET_VIEW, event_data: eventData });
};

/**
 * Track success_authenticate_email
 */
export const trackEmailSignInSuccess = () => {
  const eventData = {
    action_name: TrackingConstant.ACTION_SIGN_IN,
    subaction_name: TrackingConstant.SUCCESS_AUTHENTICATE_EMAIL,
    action_cause: 'click',
    creative_id: 'sign-in-drawer',
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_END, event_data: eventData });
};

/**
 * Track success_email_only_sign_up
 */
export const trackEmailOnlySignUpSuccess = () => {
  const eventData = {
    action_name: TrackingConstant.ACTION_SIGN_UP,
    subaction_name: TrackingConstant.SUCCESS_EMAIL_ONLY_SIGN_UP,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    creative_id: 'authenticated-modal',
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS, event_data: eventData });
};

/**
 * Fires when user inputs invalid email
 *
 * @param {Object} trackingData
 */
export function fireInvalidEmailInput(trackingData) {
  const eventData = {
    action_name: TrackingConstant.ACTION_SIGN_UP,
    subaction_name: TrackingConstant.SUBMIT_SIGN_UP_EMAIL,
    action_category: 'user',
    action_cause: 'button_click',
    creative_id: trackingData.creativeId,
    value: trackingData.value,
  };

  fireAction({ event_type: 'action_progress', event_data: eventData });
}

/**
 * Track anonymous_upgrade_save_vin or anonymous_upgrade_save_search
 */
export const authenticatedAnonymousSaveAction = value => {
  const eventData = {
    action_name: TrackingConstant.ACTION_SIGN_UP,
    subaction_name: TrackingConstant.BEGIN_SIGN_UP,
    action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
    creative_id: 'authenticated-modal',
    value: PROFILE_TRACKING_CONSTANTS[value],
  };

  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_START, event_data: eventData });
};

export function fireAuthSuccess(type, idmDataToSave = {}, authTracking = {}) {
  if (idmDataToSave.vehicles) {
    const vin = Object.keys(idmDataToSave.vehicles.vins)[0];
    authenticatedSaveVinAction(vin);
  }
  if (idmDataToSave.savedSearches) {
    const searchQuery = get(idmDataToSave, 'savedSearches[0].searchQuery');
    authenticatedSaveSearchAction(searchQuery);
  }

  successAuthAction(type);
  if (authTracking.saveModel) {
    saveModel(type);
  }
}

/**
 * Fires custom event for email (when email is not saved to anonymous profile)
 */
export const fireValidExistingEmailCustomEvent = email => {
  EventToolbox.fireCustomEvent(SMART_SIGN_VALID_EMAIL_EVENT, { email });
};

export const fireDeleteConfirmationOpen = () => {
  const pageHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);
  const eventData = {
    action_name: TrackingConstant.ACTION_WIDGET_VIEW,
    action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
    total_page_height: pageHeight,
    creative_id: PROFILE_TRACKING_CONSTANTS.INSIDER_DELETE_CONFIRMATION_CREATIVE_ID,
  };
  fireAction({ event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW, event_data: eventData });
};

const fireDeleteAction = (coreId, subActionName) => {
  const eventData = {
    action_name: TrackingConstant.ACTION_UPDATE_MEMBERSHIP,
    subaction_name: subActionName,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    value: coreId,
    creative_id: PROFILE_TRACKING_CONSTANTS.INSIDER_DELETE_CONFIRMATION_CREATIVE_ID,
  };
  fireAction({ event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED, event_data: eventData });
};

export const fireDeleteConfirmationAction = coreId => {
  fireDeleteAction(coreId, 'submit_delete');
};

export const fireDeletionSuccess = coreId => {
  fireDeleteAction(coreId, 'submit_delete_success');
};

export const fireDeletionFailed = coreId => {
  fireDeleteAction(coreId, 'submit_delete_failure');
};
