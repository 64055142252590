import {
  createProfile,
  updateProfileData,
  mixedUpdateProfileData,
  updateLastLandingTs,
  updateProfile,
  createAnonymousProfile,
  updateToCurrentThenSignInAndCreateAnonymousProfile,
  signInAndCreateAnonymousProfile,
} from 'site-modules/shared/components/profile/idm/idm';

export const PROFILE_CONSTANTS = {
  APPRAISALS_PER_PAGE: 3,
  EMPTY_DATA: [],
};

export const IDM_METHODS_MAPPING = {
  CREATE: createProfile,
  UPDATE: updateProfileData,
  UPDATE_LOGIN: updateProfile,
  CREATE_ANONYMOUS: createAnonymousProfile,
  DELETE: updateProfileData,
  LOAD: updateLastLandingTs,
  MIXED_UPDATE: mixedUpdateProfileData,
  UPDATE_CURRENT_SIGN_IN_CREATE_ANONYMOUS: updateToCurrentThenSignInAndCreateAnonymousProfile,
  SIGN_IN_CREATE_ANONYMOUS: signInAndCreateAnonymousProfile,
  CLEAR: () => Promise.resolve(undefined),
};
