export const AUTH_PROVIDERS = {
  apple: {
    providerId: 'apple.com',
    provider: 'OAuthProvider',
    profileScope: ['email', 'name'],
    authAction: 'apple',
    iconName: 'apple-carplay',
    spinner: 'appleButton',
  },
  facebook: {
    providerId: 'facebook.com',
    provider: 'FacebookAuthProvider',
    profileScope: ['public_profile', 'email'],
    authAction: 'facebook',
    iconName: 'facebook2',
    spinner: 'facebookButton',
  },
  google: {
    providerId: 'google.com',
    provider: 'GoogleAuthProvider',
    profileScope: ['profile', 'email'],
    authAction: 'googleplus',
    iconName: 'google',
    spinner: 'googleButton',
  },
  password: {
    name: 'password',
  },
};

export const PROVIDERS_MAPPING = {
  'google.com': 'google',
  'facebook.com': 'facebook',
  'apple.com': 'apple',
  password: 'email',
};
