import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signOut, toggleProfileScreen } from 'client/actions/profile';
import { PROFILE_SCREEN } from './profile-screen';

export function profileScreenMethods(WrappedComponent) {
  function ProfileScreen(props) {
    return <WrappedComponent {...props} />;
  }

  ProfileScreen.propTypes = {
    toggleProfileScreen: PropTypes.func.isRequired,
    toggleSignModal: PropTypes.func.isRequired,
    toggleForgotPassword: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = dispatch => ({
    toggleProfileScreen: profileScreen => dispatch(toggleProfileScreen(profileScreen)),
    toggleSignModal: options =>
      dispatch(toggleProfileScreen(PROFILE_SCREEN.SIGN_MODAL, options?.nativeEvent ? undefined : options)),
    toggleForgotPassword: event => {
      if (event && event.preventDefault) event.preventDefault();
      dispatch(toggleProfileScreen(PROFILE_SCREEN.FORGOT_PASSWORD_MODAL));
    },
    closeProfileScreen: () => dispatch(toggleProfileScreen('')),
    signOut: () => dispatch(signOut()),
  });

  return connect(
    null,
    mapDispatchToProps
  )(ProfileScreen);
}
