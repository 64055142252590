import { get, merge } from 'lodash';
import { getQuery } from 'client/utils/location';
import { formatSyncAttributes } from 'site-modules/shared/components/profile/attributes/sync';
import { formatIntelligentMessagingAttributes } from 'site-modules/shared/components/profile/attributes/intelligent-messaging';
import { getSyncDate } from './idm-utils';

/**
 * Get lat/lng data for pii location
 * @return {*|{pii: {location: {latitude: *, longitude: *}}}}
 */
export const populatePii = visitor => {
  const latitude = get(visitor, 'location.latitude');
  const longitude = get(visitor, 'location.longitude');
  return (
    latitude &&
    longitude && {
      pii: {
        location: {
          latitude,
          longitude,
        },
      },
    }
  );
};

/**
 * Get research postal codes
 * @param visitor
 * @return {*|{person: {researchPostalCodes: [*]}}}
 */
export const populateResearchPostalCodes = visitor => {
  const zip = get(visitor, 'location.zipCode');
  return (
    zip && {
      person: {
        researchPostalCodes: [zip],
      },
    }
  );
};

/**
 * Populate merged attributes makeModels from URL
 * @param profile
 * @param location
 * @return {*|{mergedAttributes: {makeModels: [*]}}}
 */
export const populateMakeModelsFromURL = (profile, location) => {
  const makeModels = get(profile, 'mergedAttributes.makeModels', []);
  const query = getQuery(location);
  const make = query.make;
  const model = query.model;
  if (!make || !model || makeModels.length) {
    return {};
  }

  return {
    mergedAttributes: {
      makeModels: [{ make, model }],
    },
  };
};

/**
 * Get insider source object for sign up. See MEM-426 for more information.
 * @param location - React Router 'location'
 * @returns {*}
 */
export const getInsiderSource = location => {
  const query = getQuery(location);

  return {
    source: query.insider_source,
    detail: query.insider_source_detail,
  };
};

/**
 * Populate intelligent messaging data
 * @param pageContext
 * @param location
 * @param isAnonymousUser
 * @return {Object}
 */
export const populateProspectEntry = ({ intelligentMessaging }, pageContext, location) => {
  const insiderSource = getInsiderSource(location);
  const { prospectEntryPointDetail } = intelligentMessaging || {};
  return {
    intelligentMessaging: {
      prospectCreateTs: getSyncDate(),
      prospectEntryPoint: insiderSource.source || 'website',
      prospectEntryPointDetail: prospectEntryPointDetail || insiderSource.detail || get(pageContext, 'page.name'),
    },
  };
};

/**
 * Populate last landing page visit data
 * @return {*}
 */
export const populateLastLandingPageVisitTs = () => ({
  intelligentMessaging: {
    lastLandingPageVisitTs: getSyncDate(),
  },
});

/**
 * Populate lastGeoLocation
 * @param visitor
 * @return {*}
 */
export const populateLastGeoLocation = visitor => {
  const location = visitor && visitor.location;
  if (!location) {
    return {};
  }
  const { zipCode: postalCode, stateCode: state, dma } = location;
  return {
    person: {
      location: {
        lastGeoLocation: {
          postalCode,
          state,
          dma: parseInt(dma, 10),
        },
      },
    },
  };
};

/**
 * Populate identifiers if available
 * @param {Object} visitor
 * @returns {Object}
 */
export const populateIdentifiers = (visitor = {}) => {
  const { id, session } = visitor;
  return id && session
    ? {
        identifiers: {
          edwVisitor: [id],
          edwSession: [session],
        },
      }
    : {};
};

/**
 * Populates EDW analytics data for IDM sign up
 * @param {Object} profile - source object with user profile data.
 * @param {Object} visitorAndPageContext - context object.
 * @param {Boolean} shouldPopulateProspectEntry - populates prospect entry to be part of payload
 * @returns {Object} extended user object with analytics data.
 */
export const setAnalyticsData = (profile, visitorAndPageContext = {}, shouldPopulateProspectEntry) => {
  const visitor = visitorAndPageContext.visitor;
  const pageContext = visitorAndPageContext.pageContext;
  const location = get(visitorAndPageContext, 'pageContext.location', {});

  return merge(
    profile,
    formatSyncAttributes(),
    populateIdentifiers(visitor),
    populateLastGeoLocation(visitor),
    populateResearchPostalCodes(visitor),
    populateMakeModelsFromURL(profile, location),
    populatePii(visitor),
    shouldPopulateProspectEntry && populateProspectEntry(profile, pageContext, location)
  );
};

/**
 * Populates EDW analytics data for IDM sign in
 * @param {Object} profile - source object with user profile data.
 * @param {Object} visitorAndPageContext - context object.
 * @param {Boolean} shouldPopulateProspectEntry - Should update for "isAnonymousUser"
 * @returns {Object} extended user object with analytics data.
 */
export const setAnalyticsDataForSignIn = (profile, visitorAndPageContext = {}, shouldPopulateProspectEntry) =>
  merge(
    profile,
    setAnalyticsData(profile, visitorAndPageContext, shouldPopulateProspectEntry),
    formatIntelligentMessagingAttributes()
  );
