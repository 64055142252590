import { objectToQueryString } from 'client/site-modules/shared/utils/string';
import { DIGITAL_RETAIL_PATH, LEASE, PURCHASE } from 'site-modules/shared/constants/car-buying';

/**
 * Returns TED link.
 * @param {string} vin
 * @param {boolean} [isLeaseOpened]
 * @param {string} [step]
 * @param {object} [params]
 * @return {string}
 */
export const getDRLink = (vin, isLeaseOpened = false, step, params = {}) => {
  const stepName = step ? `${step}/` : '';

  const flowType = isLeaseOpened ? LEASE : PURCHASE;

  const queryString = objectToQueryString(params);

  return `${DIGITAL_RETAIL_PATH}${vin}/${stepName}?${flowType}${queryString ? `&${queryString}` : ''}`;
};
