import { get, omit } from 'lodash';
/* Utils */
import { getVdpUrl } from 'site-modules/shared/utils/inventory-utils/get-vdp-url';
import { getDRLink } from 'site-modules/shared/utils/car-buying/cbp-link-util';
import { getCalculatedPrices } from 'site-modules/shared/utils/car-buying/calculated-prices';
import { SessionRecorder } from 'client/utils/session-recorder/session-recorder';
import { clearPhone } from 'site-modules/shared/utils/phone-format';
/* Constants */
import {
  PURCHASE_PAYMENT_TYPE,
  LEASE_PAYMENT_TYPE,
  LOAN_FINANCE_METHOD,
  TED_GENERAL_DISCLAIMERS,
} from 'site-modules/shared/constants/car-buying';

/**
 * Gets inventory information in the form suitable for receipt API.
 * @param {string} vin
 * @param {string} type
 * @param {object} styleInfo
 * @param {string}stockNumber
 * @param {number} mileage
 * @param {string} photoUrl
 * @param {number} engineSize
 * @param {number} engineCylinder
 * @param {object} colors
 * @returns {object}
 */
export function getInventoryInfo({
  vin,
  type,
  styleInfo,
  stockNumber,
  mileage,
  photoUrl,
  engineSize,
  engineCylinder,
  colors,
}) {
  return {
    vin,
    stockNumber,
    make: styleInfo.make,
    model: styleInfo.model,
    year: styleInfo.year,
    trim: styleInfo.trim,
    engineSize,
    cylinders: engineCylinder,
    mileage,
    cityMpg: styleInfo.fuel && styleInfo.fuel.epaCityMPG,
    highwayMpg: styleInfo.fuel && styleInfo.fuel.epaHighwayMPG,
    photoUrl,
    exteriorColor: colors.exterior.name,
    interiorColor: colors.interior.name,
    type,
  };
}

/**
 * Gets dealer info.
 * @param inventory
 * @returns {object}
 */
export function getDealerInfo(inventory) {
  const { rooftopId, franchiseId, name, address } = get(inventory, 'dealerInfo', {});
  const dealerPhoneNumber = get(inventory, 'computedDisplayInfo.phoneNumber', '');

  const cleanDealerPhoneNumber = clearPhone(dealerPhoneNumber);

  return {
    rooftopId,
    franchiseId,
    phoneNumber: {
      areaCode: cleanDealerPhoneNumber.slice(0, 3),
      prefix: cleanDealerPhoneNumber.slice(3, 6),
      postfix: cleanDealerPhoneNumber.slice(6, 10),
    },
    name,
    address,
  };
}

/**
 * Gets referring URLs to CBP (TED), VDP, Inspectlet session and shared page (if passed).
 * @param {object} inventory
 * @param {string} inspectletSession
 * @param {string} sharedLink
 * @param {boolean} isPurchase
 * @returns {object}
 */
export function getReferringUrls({ inventory, inspectletSession, sharedLink, isPurchase }) {
  const origin = window.location.origin;
  const cbpUrl = `${origin}${getDRLink(inventory.vin, !isPurchase)}`;
  const vdpUrl = `${origin}${getVdpUrl(inventory, false, isPurchase)}`;
  return {
    cbp: cbpUrl,
    vdp: vdpUrl,
    inspectlet: inspectletSession,
    paymentReview: sharedLink,
  };
}

/**
 * Gets payment type depending on `isPurchase` parameter.
 * @param {boolean} isPurchase
 * @returns {string}
 */
export function getPaymentType(isPurchase) {
  return isPurchase ? PURCHASE_PAYMENT_TYPE : LEASE_PAYMENT_TYPE;
}

/**
 * Converts incentives to the form suitable for receipt API.
 * @param {object[]} incentives
 * @returns {object[]|array}
 */
export function convertIncentives(incentives) {
  return incentives
    ? incentives
        .filter(({ cash, amount }) => !!cash || !!amount)
        .map(({ cash, amount, expirationDate, id, incentiveId, programName, name, programId }) => ({
          cash: cash || amount,
          expirationDate,
          incentiveId: incentiveId || id,
          programName: programName || name,
          programId: programId || id,
        }))
    : [];
}

export function getSingleOffer({ isPurchase, displayPrice, offer, estimatedPurchasePrice }) {
  const {
    annualMileage,
    apr,
    downPayment,
    dueAtSigning,
    moneyFactor,
    monthlyPayment,
    baseMonthlyPayment,
    sellingPrice,
    incentives,
    term,
    totalRebate,
    residual,
    startDateEpochMillis,
    endDateEpochMillis,
    tradeIn,
    taxes,
    fees,
  } = offer;

  return {
    annualMileage: isPurchase ? undefined : annualMileage,
    annualPercentageRate: apr && apr.toFixed(2),
    downPayment,
    dueAtSigning: isPurchase ? undefined : dueAtSigning,
    incentives: convertIncentives(incentives),
    moneyFactor,
    capitalizedFees: fees,
    capitalizedTaxes: taxes,
    monthlyPayment,
    baseMonthlyPayment,
    sellingPrice,
    msrp: displayPrice,
    displayPrice,
    tradeIn,
    totalRebate,
    term,
    residualPercentage: residual,
    residual: residual && (residual * displayPrice).toFixed(),
    startDate: startDateEpochMillis,
    endDate: endDateEpochMillis,
    estimatedPurchasePrice,
  };
}

/**
 * Gets information for multi Receipt API.
 * @param {string} zipCode
 * @param {string} phone
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} visitorId
 * @param {string} sessionId
 * @param {string} pathname
 * @param {string[]} tags
 * @param {string} creativeId
 * @param {object[]} deals
 * @returns {object}
 */
export function getMultiReceiptSubmissionInfo({
  zipCode,
  phone,
  email,
  firstName,
  lastName,
  visitorId,
  sessionId,
  pathname,
  tags,
  creativeId,
  deals,
}) {
  // get first offer since all offers contains the same information
  // then determine if passed offer are purchase offer (loan) or lease offer
  const isPurchase = get(deals, '[0].dealOffer.offerType', '').includes(LOAN_FINANCE_METHOD.toUpperCase());

  return {
    shopper: {
      firstName,
      lastName,
      email,
      zip: zipCode,
      phoneNumber: {
        areaCode: phone.slice(0, 3),
        prefix: phone.slice(3, 6),
        postfix: phone.slice(6, 10),
      },
    },
    deals: deals.map(({ inventory, franchise, photos, dealOffer }) => {
      const { vin, type, stockNumber, style, mileage, engineSize, interiorColor, exteriorColor } = inventory;
      const {
        offerType,
        taxes,
        fees,
        displayPrice,
        residualAmount,
        programStartDate,
        programEndDate,
        limitCriteria,
        totalRebate,
        sellingPrice,
        incentives,
        tmv,
        ...offer
      } = dealOffer;

      const { purchasePriceAfterRebates } = getCalculatedPrices({
        sellingPrice: tmv || displayPrice,
        incentives,
        msrp: displayPrice,
      });

      return {
        inventory: getInventoryInfo({
          vin,
          type,
          styleInfo: style,
          stockNumber,
          mileage,
          photoUrl: get(photos, '[0].url'),
          engineSize,
          colors: {
            interior: interiorColor,
            exterior: exteriorColor,
          },
        }),
        dealer: franchise,
        [isPurchase ? 'purchaseOffer' : 'leaseOffer']: {
          ...offer,
          incentives,
          displayPrice,
          sellingPrice,
          totalRebate,
          capitalizedTaxes: taxes,
          capitalizedFees: omit(fees, ['dmvFeesItemized']),
          msrp: displayPrice,
          residual: residualAmount,
          startDate: programStartDate,
          endDate: programEndDate,
          estimatedPurchasePrice: purchasePriceAfterRebates,
        },
        referringUrls: getReferringUrls({
          inventory: { ...inventory, vehicleInfo: { styleInfo: style } },
          inspectletSession: SessionRecorder.getInspectletUrl(),
          isPurchase,
        }),
      };
    }),
    leadDetails: {
      sessionId,
      visitorId,
      tags,
      creativeId,
      skipLeadEmail: true,
      approvedStatus: false,
      bannerCode: Date.now(),
      referringUrl: `${window.location.origin}${pathname}`,
    },
    paymentType: getPaymentType(isPurchase),
  };
}

/**
 * Gets information for single Receipt API.
 * @param {string} zipCode
 * @param {string} phone
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} visitorId
 * @param {string} sessionId
 * @param {string[]} tags
 * @param {string} creativeId
 * @param {object[]} deals
 * @returns {object}
 */
export function getSingleReceiptSubmissionInfo({
  zipCode,
  phone,
  email,
  firstName,
  lastName,
  visitorId,
  sessionId,
  tags,
  creativeId,
  deals,
}) {
  const { inventory, photo, dealOffer, calculatedData, leadDetails = {} } = deals[0];

  const { vin, type, stockNumber, vehicleInfo } = inventory;

  const { sellingPrice, incentives, creditScore, isPurchase } = dealOffer;
  const { tradeInDetails } = calculatedData;
  const { isPriceFocused } = leadDetails;

  const displayPrice = get(inventory, 'prices.displayPrice');

  const { purchasePriceAfterRebates } = getCalculatedPrices({
    sellingPrice,
    incentives,
    msrp: displayPrice,
  });

  return {
    shopper: {
      firstName,
      lastName,
      email,
      zip: zipCode,
      phoneNumber: {
        areaCode: phone.slice(0, 3),
        prefix: phone.slice(3, 6),
        postfix: phone.slice(6, 10),
      },
      approximateCreditScore: creditScore,
    },
    inventory: getInventoryInfo({
      vin,
      type,
      styleInfo: vehicleInfo.styleInfo,
      stockNumber,
      mileage: vehicleInfo.mileage,
      photoUrl: photo,
      engineSize: get(vehicleInfo, 'partsInfo.engineSize'),
      colors: vehicleInfo.vehicleColors,
    }),
    dealer: getDealerInfo(inventory),
    [isPurchase ? 'purchaseOffer' : 'leaseOffer']: getSingleOffer({
      isPurchase,
      displayPrice,
      offer: dealOffer,
      estimatedPurchasePrice: purchasePriceAfterRebates,
    }),
    tradeInDetails,
    referringUrls: getReferringUrls({
      inventory,
      inspectletSession: SessionRecorder.getInspectletUrl(),
      isPurchase,
    }),
    leadDetails: {
      sessionId,
      visitorId,
      tags: isPriceFocused ? tags : [...tags, '#digital_retail'],
      creativeId,
      skipLeadEmail: !isPriceFocused,
      defaultComments: isPriceFocused,
      approvedStatus: false,
      bannerCode: Date.now(),
      referringUrl: `${window.location.origin}${window.location.pathname}`,
      prequalifyPresented: false,
    },
    disclaimer: `${TED_GENERAL_DISCLAIMERS[0]}<br><br>${TED_GENERAL_DISCLAIMERS[1]}<br><br>`,
    paymentType: getPaymentType(isPurchase),
  };
}

export function getSingleReceiptSubmissionInfoWithoutEmail(params) {
  const submissionInfo = getSingleReceiptSubmissionInfo(params);

  return {
    ...submissionInfo,
    leadDetails: {
      ...submissionInfo.leadDetails,
      skipLeadEmail: true,
    },
  };
}

export const getSubmissionInfo = deals => data => getSingleReceiptSubmissionInfo({ deals, ...data });
