import { intersectionWith, isEqual, get, set, forIn } from 'lodash';
import { PhotosModel } from 'client/data/models/photos';
import { getMediaImageUrl } from 'client/utils/image-helpers';

/**
 * Fetches stock images for provided style ids
 * @param {Array}  styleIds Of vehicles without photo provided
 * @param {Object}  context Model segment context
 * @param {String}  provider Photo provider
 * @param {String}  shottype Shot type priority for fetching images
 */
export const fetchStockImages = (styleIds, context, provider, shottype, width) =>
  context.resolveValue(
    `styleIds["${styleIds.join(',')}"].provider["${provider}"].shottype["${shottype}"].width["${width}"]`,
    PhotosModel
  );

/**
 * Collects style ids of items without photo
 * @param {Object|Array} data With vehicle data objects
 * @returns {Array} Style ids for vehicle data without photo
 */
export const collectStyleIds = (data, size = 'large') => {
  const styleIds = [];

  forIn(data, value => {
    const styleIdPath = value.vehicleInfo ? 'vehicleInfo.styleInfo.styleId' : 'styleId';

    const styleId = get(value, styleIdPath, '');
    const photo = get(value, `vehicleInfo.photo.defaultPhoto.${size}.url`, '');

    if (!value.isSoldVin && styleId && !photo) {
      styleIds.push(styleId.toString());
    }
  });
  return styleIds;
};

/**
 * Sets appropriate photos for provided style ids sorted by provider ("EVOX" should be first)
 * @param {Object} json Fetch photos response
 * @param {Array}  styleIds Of vehicles without photo provided
 * @returns {Object} ex. { '123456': '/image_name.jgg' }
 */
export const getStockPhotos = (json, styleIds) =>
  get(json, 'photos', []).reduce((stockPhotos, item) => {
    const ids = intersectionWith(styleIds, item.styleIds, isEqual);
    ids.forEach(id => {
      if (!get(stockPhotos, id)) set(stockPhotos, id, get(item, 'sources[0].link.href'));
    });
    return stockPhotos;
  }, {});

/**
 * Gets stock photos and sets url in provided vehicle data object.
 * @param {Object} json Fetched stock photos data
 * @param {Object} data Vehicles data
 * @param {Array} styleIds Of vehicles without photo provided
 * @param {String} photoSize
 */
export const mapPhotosToData = (json, data, styleIds, photoSize) => {
  const dataValues = Object.values(data);
  const stockPhotos = getStockPhotos(json, styleIds);

  Object.keys(stockPhotos).forEach(styleId => {
    const dataByStyleIds = dataValues.filter(element =>
      element.vehicleInfo
        ? get(element, 'vehicleInfo.styleInfo.styleId', '').toString() === styleId
        : element.styleId && element.styleId.toString() === styleId
    );

    dataByStyleIds.forEach(dataByStyleId => {
      const pathToPhoto = dataByStyleId.vehicleInfo ? `vehicleInfo.photo.defaultPhoto.${photoSize}.url` : 'photoUrl';
      const hasPhoto = get(dataByStyleId, pathToPhoto);
      if (!hasPhoto) {
        set(dataByStyleId, 'isStockPhoto', true);
        set(dataByStyleId, pathToPhoto, getMediaImageUrl(stockPhotos[styleId]));
      }
    });
  });
};

/**
 * Fetches stock photos and maps these photos to provided data.
 * @param {Object} data - Vehicles data to fill with stock photos
 * @param {Object} context - Model segment context
 * @param {String} provider - Photo provider
 * @param {String} shottype - Shot type priority
 * @param {String} width - width
 * @param {String} size - photo size
 * @returns {Promise.<Array>} Array of data values with / without stock photos
 */
export const fetchImages = (
  data,
  context,
  provider = 'OEM',
  shottype = 'F,FQ,FQN,F34',
  width = '500',
  size = 'large'
) => {
  const styleIds = collectStyleIds(data, size);
  if (styleIds.length) {
    return fetchStockImages(styleIds, context, provider, shottype, width)
      .then(json => mapPhotosToData(json, data, styleIds, size))
      .then(() => Object.values(data));
  }
  return Promise.resolve(Object.values(data));
};
