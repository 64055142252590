export const INCENTIVE_VALUES = {
  AMOUNT: 'amount',
  REBATE: 'rebateAmount',
  CASH: 'cash',
  SOURCE_TYPE: 'sourceTypeValue',
  TAX_REBATE_AMOUNT: 'taxRebateAmount',
};

export const INCENTIVE_CUSTOMER_CASH = 'CUSTOMER_CASH';

export const INCENTIVE_NAMES = {
  [INCENTIVE_CUSTOMER_CASH]: 'Customer Cash',
  CUSTOMER_BONUS_CASH: 'Bonus Cash',
};

export const INCENTIVE_INPUT_TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

export const DESTINATION_FEE_COPY = 'Typically included in MSRP. Contact dealer for details.';
export const DESTINATION_FEE_COPY_SHORT = 'Included in MSRP. Contact dealer for details.';
