import { get, isEmpty } from 'lodash';
import { fireFirebaseAnonymousAuthAction } from 'client/engagement-handlers/firebase-engagement-handler/firebase-engagement-handler';
import { getSavableData } from 'site-modules/shared/components/profile/idm/idm-utils';
import { auth, onReject } from './firebase-auth';

/**
 * Fires tracking event on anonymous user sign in
 */
export const fireFirebaseTracking = (creativeId, trackingData) =>
  fireFirebaseAnonymousAuthAction(get(auth(), 'currentUser.uid'), creativeId, trackingData);

/**
 * Signs in user in firebase anonymously
 * @returns {Object} Promise.
 */
export const signInAnonymously = (creativeId, trackingData = {}) =>
  auth()
    .signInAnonymously()
    .then(() => fireFirebaseTracking(creativeId, trackingData))
    .catch(error => onReject(error, creativeId));

export const constructUpgradePayload = (email, idmData) => ({
  ...getSavableData(idmData),
  identifiers: { email: [email] },
});

/**
 * Return true if user is anonymous user with at least one appraisal history record
 * @param {Object} state
 * @returns {Boolean}
 */
export const isAnonymousUserWithAppraisalData = state => {
  const IDM_PATH = 'profile.data.idm';
  return (
    get(state, 'profile.isAnonymousUser') &&
    !isEmpty(get(state, `${IDM_PATH}.vehicles.appraisalHistory`)) &&
    isEmpty(get(state, `${IDM_PATH}.vehicles.vins`)) &&
    isEmpty(get(state, `${IDM_PATH}.leads`)) &&
    isEmpty(get(state, 'profile.data.savedSearches'))
  );
};
