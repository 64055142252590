import { isEmpty, orderBy, get, set, cloneDeep, map, partialRight, uniqBy, difference } from 'lodash';
import { VisitorModel } from 'client/data/models/visitor';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { getProfile } from 'site-modules/shared/components/profile/idm/idm';
import { DMA, ZIP_CODE } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { UserProfileModel } from './profile';

/**
 * Defines method to interact with IDM data
 * @param {String} method Method name
 * @returns {Function|getProfile} Callback or getProfile
 */
export const getIdmCallback = method => {
  const CALLBACK_MAPPING = {
    CLEAR: () => undefined,
  };
  return CALLBACK_MAPPING[method] || getProfile;
};

/**
 * Resolves zip code and idm data according to provided context.
 * @param {Object} context  model segment context
 * @param {Boolean} dependency
 * @return {Promise} - {Array} [idm: {Object}, zipCode: {String}]
 */
export const getZipAndIdmValues = (context, dependency = false) =>
  Promise.all([
    context.resolveValue('location.zipCode', VisitorModel, dependency),
    context.resolveValue('data.idm', UserProfileModel),
  ]);

/**
 * Filters and sorts array of objects by provided field name.
 * @param {Array} data With objects of vehicle data
 * @param {Boolean} filterByOrd should be filtered
 * @param {String} ordBy Field name to sort
 * @param {String} ord Order to sort
 * @return {Array} filtered and sorted by provided value
 */
export const sortByField = (data, filterByOrd = false, ordBy = 'savedTs', ord = 'desc') => [
  ...orderBy(data.filter(item => !!item[ordBy]), [ordBy], [ord]),
  ...(!filterByOrd ? data.filter(item => !item[ordBy]) : []),
];

export const sortByFields = (data, ordBy = ['active', 'savedTs'], ord = ['desc', 'desc']) => orderBy(data, ordBy, ord);

/**
 * Filters and sorts array of objects by priceDrop.
 * @param {Array} data with objects of vehicle data
 * @return {Array} sorted array
 */
export const getSortedValidPriceDrops = data => [
  ...orderBy(data.filter(vin => vin.hasNewAlert), 'priceDrop'),
  ...sortByField(data.filter(vin => !vin.hasNewAlert)),
];

/**
 * Applies pagination for sorted data by provided page number and items per page values.
 * @param {Array} data
 * @param {Number} pageNum
 * @param {Number} itemsPerPage
 * @returns {Array} with applied pagination
 */
export const applyPagination = (data, pageNum, itemsPerPage) => {
  const index = (pageNum - 1) * itemsPerPage;
  return data.slice(index, index + itemsPerPage);
};

/**
 * Excludes non vin vehicles from the result (nonVin === true)
 * @param {Array} data
 * @returns {Array} with vehicles
 */
export const filterNonVinVehicles = data => data.filter(vin => !vin.nonVin);

/**
 * @param {Object}  idm User IDM data
 * @param {String}  path Data path
 * @param {String}  keyName Key to set unique value in cloned data object
 * @param {Number}  pageNum
 * @param {Number}  itemsPerPage
 * @returns {Array} Of sorted objects with applied pagination
 */
export const transformSavedData = (idm, path, keyName, pageNum = 1, itemsPerPage = 20) => {
  const userSavedData = get(idm, path, {});
  const dataCopy = map(userSavedData, (value, key) => ({ [keyName]: key, ...cloneDeep(value) }));
  const sortedData = sortByField(filterNonVinVehicles(dataCopy), true);
  return applyPagination(sortedData, pageNum, itemsPerPage);
};

/**
 * @param {Array}  data - user data
 * @param {Array}  alerts - user alerts
 * @returns {Array} sorted array with price drops
 */
export const applyAlerts = (data, alerts) => {
  let withNewAlerts = false;
  if (!alerts.length) {
    return sortByFields(data);
  }

  data.forEach(vehicle => {
    const alert = alerts && alerts.find(a => get(a, 'alertData.vinReference') === vehicle.vin);
    if (alert) {
      const hasNewAlert = !alert.alertViewedTs;
      set(vehicle, 'priceDrop', parseInt(get(alert, 'alertData.priceDrop'), 10));
      set(vehicle, 'hasNewAlert', hasNewAlert);
      if (hasNewAlert) {
        withNewAlerts = true;
      }
    }
  });
  const sortingFunction = withNewAlerts ? getSortedValidPriceDrops : sortByFields;
  return sortingFunction(data);
};

/**
 * @param {Object}  userSavedData User IDM data
 * @param {Array}   alerts User new alerts data
 * @param {Array}   activeVins active/expired vins
 * @param {Number}  pageNum
 * @param {Number}  itemsPerPage
 * @returns {Array} Of sorted objects with applied pagination
 */
export const transformSavedDataWithAlerts = (
  userSavedData,
  alerts,
  activeVins = [],
  pageNum = 1,
  itemsPerPage = 20
) => {
  const dataCopy = map(userSavedData, (value, key) => ({
    vin: key,
    active: activeVins.includes(key),
    ...cloneDeep(value),
  }));
  const sortedData = applyAlerts(filterNonVinVehicles(dataCopy), alerts);
  return applyPagination(sortedData, pageNum, itemsPerPage);
};

/**
 * Maps fetched data to user data from IDM
 * @param {Object} data User data from IDM
 * @param {Object} fetchedData Fetched data
 * @param {Function} cb Callback to transform data
 * @returns {Object} ex. {id_1: {}, id_2: {}}
 */
export const mapLoadedData = (data, fetchedData, cb) =>
  Object.keys(data).reduce((result, id) => {
    const fetchedDataById = get(fetchedData, id, {});
    const newData = cb(data[id], fetchedDataById);
    if (newData) {
      set(result, id, newData);
    }
    return result;
  }, {});

/**
 * Sets inventory data for sold / unsold inventories
 * @param {Object} savedVinData Vin data from IDM (for one vehicle)
 * @param {Object} inventoryData Inventory data (for one vehicle)
 * @return {Object} Inventory data copy (unsold vehicle) OR IDM saved vehicle data (sold vehicle)
 */
export const setInventoryData = (savedVinData, inventoryData) =>
  isEmpty(inventoryData)
    ? { isSoldVin: true, ...savedVinData }
    : {
        savedTs: savedVinData.savedTs,
        hasNewAlert: savedVinData.hasNewAlert,
        priceDrop: savedVinData.priceDrop,
        ...cloneDeep(inventoryData),
      };

/**
 * Merges price values with appraisal data from IDM
 * @param {Object} appraisalData (for one appraisal)
 * @param {Object} priceValues (for one appraisal)
 * @return {Object} Merged prices and appraisal data shallow copies
 */
export const setAppraisalPrices = (appraisalData, priceValues) =>
  !isEmpty(priceValues) && { ...appraisalData, ...priceValues };

/**
 * Partially applies callback "setInventoryData" for mapLoadedData as argument.
 */
export const mapInventoryData = partialRight(mapLoadedData, setInventoryData);

/**
 * Partially applies callback "setAppraisalPrices" for mapLoadedData as argument.
 */
export const mapAppraisalData = partialRight(mapLoadedData, setAppraisalPrices);

/**
 * Created in order to resolve even failed promises (while promise.all rejects when one promise fails)
 * @param {Object} promiseObj Contains promises
 * @returns {Promise} With resolved promiseObj values
 */
export const waitForAppraisals = promiseObj => {
  let numberOfResolved = 0;
  const resolvedData = {};

  return new Promise(resolve => {
    const keys = Object.keys(promiseObj);
    keys.forEach(id => {
      promiseObj[id]
        .then(value => set(resolvedData, id, value))
        .catch(() => {})
        .then(() => {
          numberOfResolved += 1;
          if (numberOfResolved === keys.length) {
            resolve(resolvedData);
          }
        });
    });
  });
};

/**
 * Collects request params and generate object with promises.
 * @param {Object} appraisalHistory Appraisal data from IDM
 * @param {Object} context Model segment context
 * @param {String} zip
 * @return {Object} Promise object
 */
export const createAppraisalPromiseObj = (appraisalHistory, context, zip) => {
  const promiseObj = {};

  return appraisalHistory.reduce((result, usedTmvData) => {
    const { styleId = '', mileage = '', condition = '', id = '', options = [] } = usedTmvData;
    const optionsValues = options.map(option => option.optionCode);
    const colorId = get(usedTmvData, 'colors.exterior', '');

    if (styleId && id) {
      set(
        result,
        id,
        withMetrics(EdmundsAPI, context).fetchJson(
          `/v2/usedtmv?styleId=${styleId}&zipCode=${zip}&mileage=${mileage}&tmvCondition=${condition.toUpperCase()}&colorId=${colorId}&optionIds=${optionsValues.join(
            ','
          )}&view=full`
        )
      );
    }
    return result;
  }, promiseObj);
};

/**
 * @param alerts - alerts
 * @param idm - idm
 * @returns {Array} transformed alerts
 */
export const transformAlerts = (alerts, idm) =>
  uniqBy(
    alerts
      .filter(
        alert =>
          ['SUGGESTED_VIN', 'SUGGESTED_PRICE_DROP'].includes(alert.alertType) &&
          !get(idm, 'vehicles.vins')[alert.alertData.vinReference]
      )
      .map(alert => ({ vin: alert.alertData.vinReference, type: alert.alertType })),
    'vin'
  );

/**
 * @param results - results
 * @param alerts - alerts
 * @returns {Array} Promise resolve object
 */
export const mapAlertResults = (results, alerts) => {
  results.forEach((result, index) => {
    const resultAlert = alerts.find(alert => alert.vin === result.vin);
    if (resultAlert) {
      set(results[index], 'alertType', resultAlert.type);
    }
  });
  return results;
};

/**
 * Gets recently viewed vins excluding vins that are saved from the list.
 * @param {*} idm - user data
 * @param {Array} alerts - array of alerts
 * @param {Object[]} recentlyViewedVins - array of recently viewed vins
 */
export const getRecentlyViewedVins = (idm, recentlyViewedVins, alerts) => {
  const vehiclesVins = get(idm, 'vehicles.vins', {});
  const dataCopy = map(vehiclesVins, (value, key) => ({ id: key, vin: key, ...cloneDeep(value) }));
  const savedVinList = filterNonVinVehicles(dataCopy).map(vins => vins.id);
  const list = difference(recentlyViewedVins, savedVinList).map(vin => ({ vin }));
  return applyAlerts(list, alerts).map(vins => vins.vin);
};

/**
 *  Transforms the recently view vins list to be inventory vin list (maintains order of recentlyViewedVins) and filters out undefined data.
 * @param {Object[]} recentlyViewedVins - array of recently viewed vins
 * @param {Object[]} inventories - inventories list with all the vin data.
 */
export const transformRecentlyViewedVins = (recentlyViewedVins, inventories) =>
  recentlyViewedVins.map(vin => inventories.find(i => vin === i.vin)).filter(item => item);

/**
 * Filters saved searches without build search.
 * @param {Object[]} savedSearches
 */
export const filterSavedSearches = savedSearches => savedSearches.filter(search => !search.isBuildSearch);

export const getVinsRequest = ({ vin, pagesize, pagenum = 1, location = {} }) => {
  const { zipCode, dma, latitude, longitude, stateCode } = location;
  const latLongQueryParams = !!latitude && !!longitude ? { lat: latitude, lon: longitude } : {};
  const defaultRadiusParam = dma ? { [DMA]: dma } : {};

  return objectToQueryString({
    vin,
    pagesize,
    pagenum,
    fetchFacets: false,
    fetchSuggestedFacets: false,
    searchable: 'true,false',
    [ZIP_CODE]: zipCode,
    stateCode,
    ...defaultRadiusParam,
    ...latLongQueryParams,
  });
};
