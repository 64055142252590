import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getStaticImageUrl, getImageUrl } from 'client/utils/image-helpers';
import { NO_VEHICLE_GRAY_IMAGE_SEDAN } from 'site-modules/shared/constants/images';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';

export function onClick(onClickUrl) {
  if (!onClickUrl) return null;

  return function handleClick(e) {
    e.currentTarget.href = onClickUrl; // eslint-disable-line no-param-reassign
  };
}

export function onError(enableOnError, onErrorImage) {
  if (!(enableOnError && onErrorImage)) return null;

  return function handleError(e) {
    e.currentTarget.src = onErrorImage; // eslint-disable-line no-param-reassign
  };
}

/**
 * No vehicle image url
 */
export const NO_VEHICLE_IMAGE = getStaticImageUrl(NO_VEHICLE_GRAY_IMAGE_SEDAN);

/**
 * NoVehicleImg component
 * @param {Object} props Component properties
 */
export function NoVehicleImg(props) {
  const { clickUrl, onClickUrl, className, alt, target, isLazyLoad, attrWidth, attrHeight } = props;
  const imageComponent = (
    <img
      className="core-img img-fluid"
      src={NO_VEHICLE_IMAGE}
      alt={alt}
      width={attrWidth || null}
      height={attrHeight || null}
      {...(isLazyLoad ? { loading: 'lazy' } : {})}
    />
  );

  return (
    <div className={className}>
      {clickUrl ? (
        <a href={clickUrl} onClick={onClick(onClickUrl)} target={target}>
          {imageComponent}
        </a>
      ) : (
        imageComponent
      )}
    </div>
  );
}
NoVehicleImg.propTypes = {
  clickUrl: PropTypes.string,
  onClickUrl: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  target: PropTypes.string,
  attrWidth: PropTypes.string,
  attrHeight: PropTypes.string,
};
NoVehicleImg.defaultProps = {
  clickUrl: '',
  onClickUrl: '',
  className: '',
  alt: '',
  target: '',
  attrWidth: '',
  attrHeight: '',
};

/**
 * ImageClientSideWrapperCheck component - to apply client side render on images or not.
 * @param {Object} props Component properties
 */
export function ImageClientSideWrapperCheck(props) {
  const { children, isClientSideRender } = props;

  return isClientSideRender ? <ClientSideRender>{children}</ClientSideRender> : children;
}

ImageClientSideWrapperCheck.propTypes = {
  children: PropTypes.node.isRequired,
  isClientSideRender: PropTypes.bool,
};

ImageClientSideWrapperCheck.defaultProps = {
  isClientSideRender: false,
};

/**
 * CoreImg component
 * @param {Object} props Component properties
 */
export function CoreImg(props) {
  const {
    image,
    clickUrl,
    onClickUrl,
    clickable,
    className,
    imageClassName,
    alt,
    title,
    imgDescription,
    enableOnError,
    onErrorImage = NO_VEHICLE_IMAGE,
    target,
    source,
    width,
    isClientSideRender,
    attrWidth,
    attrHeight,
    isLazyLoad,
    isFetchpriority,
    style,
    ...options
  } = props;

  if (!image) {
    return (
      <NoVehicleImg
        clickUrl={clickUrl}
        onClickUrl={onClickUrl}
        className={className}
        alt={alt}
        target={target}
        attrWidth={attrWidth}
        attrHeight={attrHeight}
        isLazyLoad={isLazyLoad}
      />
    );
  }

  const imageComponent = (
    <ImageClientSideWrapperCheck isClientSideRender={isClientSideRender}>
      <img
        className={classnames('core-img img-fluid', imageClassName)}
        itemProp="contentUrl"
        src={options.format === 'svg' ? getStaticImageUrl(image) : getImageUrl(image, width, source)}
        alt={alt}
        title={title}
        onError={onError(enableOnError, onErrorImage)}
        width={attrWidth || null}
        height={attrHeight || null}
        {...(isLazyLoad && !isFetchpriority ? { loading: 'lazy' } : {})}
        {...(isFetchpriority && !isLazyLoad ? { fetchpriority: 'high', loading: 'eager' } : {})}
        style={style}
      />
    </ImageClientSideWrapperCheck>
  );

  return (
    <div className={className} itemScope itemType="https://schema.org/ImageObject">
      {clickable
        ? !!clickUrl && (
            <a href={clickUrl} onClick={onClick(onClickUrl)} target={target}>
              {imageComponent}
            </a>
          )
        : imageComponent}
    </div>
  );
}
CoreImg.propTypes = {
  image: PropTypes.string,
  clickUrl: PropTypes.string,
  onClickUrl: PropTypes.string,
  clickable: PropTypes.bool,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  imgDescription: PropTypes.string,
  enableOnError: PropTypes.bool,
  source: PropTypes.string,
  width: PropTypes.number,
  onErrorImage: PropTypes.string,
  target: PropTypes.string,
  isClientSideRender: PropTypes.bool,
  attrWidth: PropTypes.string,
  attrHeight: PropTypes.string,
  isLazyLoad: PropTypes.bool,
  isFetchpriority: PropTypes.bool,
  style: PropTypes.shape({}),
};
CoreImg.defaultProps = {
  image: '',
  clickUrl: '',
  onClickUrl: '',
  clickable: true,
  className: '',
  imageClassName: '',
  alt: '',
  title: '',
  imgDescription: '',
  enableOnError: false,
  source: '',
  width: 0,
  onErrorImage: '',
  target: '',
  isClientSideRender: false,
  attrWidth: '',
  attrHeight: '',
  isLazyLoad: true,
  isFetchpriority: false,
  style: undefined,
};
