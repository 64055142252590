const root = '/insider/';

export const INSIDER_PATHS = {
  INSIDER: root,
  APPRAISAL_PAGE: `${root}appraisal/`,
  SIGN_UP_PAGE: `${root}sign-up/`,
  SETTINGS_PAGE: `${root}settings/`,
  INVENTORY_PAGE: `${root}inventory/`,
  SEARCH_PAGE: `${root}search/`,
  FAVORITE_MODELS: `${root}favorite-models/`,
  FEEDBACK_FORM: 'https://tier3.wufoo.com/forms/leave-feedback/',
};
