import { clamp, inRange } from 'lodash';
import md5 from 'md5';
import { MAX_WIDTH, MIN_WIDTH, getClosestSizeToFit } from './image-helpers';

export const ASPECT = {
  _3x2: [[1600, 1067], [1280, 855], [600, 400], [300, 200], [175, 117]],
  _16x7: [[1600, 700], [1280, 560], [600, 263], [400, 175], [360, 220], [300, 131], [180, 110]],
};

/**
 * Returns closest image width available
 * @param width requested size
 * @return width
 */
function getClosestWidth(width) {
  if (!inRange(width, MIN_WIDTH, MAX_WIDTH)) {
    return clamp(width, MIN_WIDTH, MAX_WIDTH);
  }
  return width;
}

/**
 * Returns image uri for a given vin
 * @param vin
 * @param counter - nth image for a given vin. NOTE: counter in banquet begins with 1 not 0.
 * @param width - derired width.
 * @param aspect - desired aspect ratio. NOTE: null value means only width param usage
 * @returns {*}
 */
// eslint-disable-next-line no-underscore-dangle
export function getVinImage(vin, counter, width, aspect = ASPECT._3x2) {
  const vinMD5 = md5(vin.toLowerCase());
  const first2char = vinMD5.substr(0, 2);

  // Aspect ratio is provided as null, include only width in result image URL
  if (aspect === null) {
    const w = getClosestWidth(width);
    return `/for-sale/${first2char}-${vin.toLowerCase()}/img-${counter}-${w}x.jpg`;
  }

  const [w, h] = getClosestSizeToFit(width, aspect);
  // Sample banquet URL: /for-sale/ad-sadcj2fx8ja253819/img-13-600x400.jpg
  return `/for-sale/${first2char}-${vin.toLowerCase()}/img-${counter}-${w}x${h}.jpg`;
}
