/**
 * Tracking creative ids
 */
export const PROFILE_TRACKING_CONSTANTS = {
  SIGN_IN_SOCIAL_CREATIVE_ID: 'sign-in-social',
  SIGN_IN_DRAWER_CREATIVE_ID: 'sign-in-drawer',
  SIGN_UP_DRAWER_CREATIVE_ID: 'sign-up-drawer',
  AUTHENTICATED_SAVE_VIN: 'authenticated_save_vin',
  ANONYMOUS_UPGRADE_SAVE_VIN: 'anonymous_upgrade_save_vin',
  AUTHENTICATED_SAVE_SEARCH: 'authenticated_save_search',
  ANONYMOUS_UPGRADE_SAVE_SEARCH: 'anonymous_upgrade_save_search',
  AUTHENTICATED_SAVE_APPRAISAL: 'save_vehicle_appraisal',
  AUTH_ACTION_CREATIVE_ID: 'auth-action',
  ANONYMOUS_ACCOUNT_UPGRADE_CREATIVE_ID: 'anonymous-account-upgrade',
  INSIDER_DELETE_CONFIRMATION_CREATIVE_ID: 'insider-delete-confirmation',
};
