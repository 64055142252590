export const SECRETS_MANAGER_FIREBASE = {
  name: 'firebase',
  keys: {
    apiKey: 'apiKey',
    authDomain: 'authDomain',
    databaseURL: 'databaseURL',
    messagingSenderId: 'messagingSenderId',
    projectId: 'projectId',
    storageBucket: 'storageBucket',
    googleId: 'googleId',
  },
};
