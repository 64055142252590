export const DIGITAL_RETAIL_PATH = '/digital-retail/';
export const LANDING_PATH = '/car-leasing-online/';
export const CAR_BUYING_DEALS_API_URL = '/car-buying/v1/digital-retail/deals';

export const CREDIT_MIN_SCORE = 625;
export const CREDIT_MAX_SCORE = 900;
export const CREDIT_STEP = 25;
export const DEFAULT_CREDIT_SCORE = 800;
export const DEFAULT_USED_CREDIT_SCORE = 700;

export const STORAGE_KEYS = {
  CALCULATED_OFFER: 'calculatedOffer',
  HIDE_INTERSTITIAL: 'hideInterstitial',
  HIDE_INTERSTITIAL_PER_SESSION: 'hideInterstitialPerSession',
  PROFILE: 'profileData',
  TOTAL_MONTHLY_PAYMENT: 'totalMonthlyPayment',
  VIN: 'vins["{vin}"]',
  LAST_VISITED_STEP: 'lastVisitedStep',
  VISITED_TAB: 'visitedTab',
  LEASE_PREFERENCES: 'preference.lease',
  LOAN_PREFERENCES: 'preference.loan',
  TAXES_AND_FEES_ZIP_PREFERENCES: 'preference.taxesAndFeesZip',
  INCENTIVES: 'appliedIncentives',
  DELIGHTED_SURVEY_COMPLETED: 'isDelightedSurveyCompleted',
};

export const LEASE_CONDITIONALS = { loyalty: true, military: true, colleagegrad: true, colleagestudent: true };

export const DIGITAL_RETAIL_STATES = ['loan', 'lease'];
export const [PURCHASE, LEASE] = DIGITAL_RETAIL_STATES;
export const DEALER_REVIEW_PARAM = 'dealer';

export const TED_GENERAL_DISCLAIMERS = [
  'Payment includes our best available estimates of government fees and taxes, any finance charges applicable to a financed purchase, any electronic filing charge, and any emission testing charge. Actual monthly payments will vary. Does not represent a financing offer or guarantee of credit. Dealer is under no obligation to include or offer any additional equipment, products or services.',
  'This vehicle is offered subject to prior sale; accordingly, there is no guarantee that this vehicle will not be sold or leased to another individual.',
];

export const SHARE_CONSUMER_SUBSTR = 'share_consumer';

export const LEASE_FINANCE_METHOD = 'Lease';
export const LOAN_FINANCE_METHOD = 'Loan';

export const PURCHASE_PAYMENT_TYPE = 'PURCHASE';
export const LEASE_PAYMENT_TYPE = 'LEASE';

export const DEFAULT_CARDS_NUMBER = 12;
export const TED_VIN_FINANCING_DEFAULTS = {
  NEW_LOAN: {
    term: 60,
    tradeIn: 0,
    downPayment: 0,
    creditScore: 800,
    financeIntent: 'PURCHASE',
  },
  USED_LOAN: {
    term: 60,
    tradeIn: 0,
    downPayment: 0,
    creditScore: 725,
    financeIntent: 'PURCHASE',
  },
  LEASE: {
    term: 36,
    annualMileage: 12000,
    tradeIn: 0,
    downPayment: 1000,
    creditScore: 800,
    financeIntent: 'LEASE',
  },
};

export const DEALER_MESSAGE = "Here's the deal I've built. I want to know your lowest out-the-door-price. Thanks!";

export const SPECIAL_LOCATION_PARAMS = {
  // used if page name should be redefined with passed, e.g. `?fpn=my_page` will set my_page to `pageName`
  FORCED_PAGE_NAME: 'fpn',
  // used to redirect user to some page (need a logic to handle each page)
  REDIRECT: 'r',
  // used for handling tracking event fire that indicates from where user received SMS with a link, e.g.
  // `?tg=lead`, if from some A/B `?tg=lead_TED_3333`
  TARGET: 'tg',
  // needed to indicate that user was redirected to the page, so we can show highlights with explanations,
  // e.g. `rd=true`
  REDIRECTED: 'rd',
};
