import { times, get } from 'lodash';

import { getStaticImageUrl, getEdmundsImageUrl, getMediaImageUrl } from 'client/utils/image-helpers';
import { NO_VEHICLE_GRAY_IMAGE_SEDAN } from 'site-modules/shared/constants/images';
import { getVinImage } from 'client/utils/inventory-image-helper';
import { PHOTO_TYPE, DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { getNoVehicleImageToDisplay } from 'site-modules/shared/utils/get-no-vehicle-image-to-display';

export const DEFAULT_PHOTO = getStaticImageUrl(NO_VEHICLE_GRAY_IMAGE_SEDAN);
export const DEFAULT_WIDTH = 600;
export const LARGE_VDP_WIDTH = 960;
export const THUMBNAIL_WIDTH_DESKTOP = 360;
export const THUMBNAIL_WIDTH_MOBILE = 180;

/**
 * Check existing of dealer photo and after finds the provider type.
 * @param {object} vinData
 * @returns {string}
 */
export function getPhotoProvider(vinData) {
  const largePhotoCount = get(vinData, DATA_PATH.SRP_DEALER_PHOTO, 0);
  const providerPhotoType = get(vinData, DATA_PATH.STOCK_PHOTO_PROVIDER, '');
  return largePhotoCount === 0 ? providerPhotoType : '';
}

/**
 * Check if the vehicle photo is of stock type
 * @param {object} vinData
 * @returns {boolean}
 */
export function getIsStockPhotoType(vinData) {
  const provider = getPhotoProvider(vinData);
  return [PHOTO_TYPE.EVOX, PHOTO_TYPE.OEM].includes(provider);
}

/**
 * Return urls and photo providers
 * @param url
 * @param index
 * @param dealerPhotoCount
 * @param stockPhotoUrls
 * @param inventory
 * @param hasPhotos
 * @returns {{photoType: (string|string), url: string}}
 */
function getPhotoUrlsWithPhotoTypes({ url, index, dealerPhotoCount, stockPhotoUrls, inventory, hasPhotos }) {
  let photoType;

  if (dealerPhotoCount) {
    photoType = PHOTO_TYPE.DEALER;
  } else if (stockPhotoUrls.length) {
    photoType = get(inventory, `stockPhotoInfo.photos[${index}].provider`, PHOTO_TYPE.DEFAULT);
  }

  return {
    url: getEdmundsImageUrl(url),
    photoType: hasPhotos ? photoType : PHOTO_TYPE.NO_IMAGE,
  };
}

/**
 * Returns a list of photos available
 * @param {Object} inventory
 * @param {number} width - Image width
 * @param {number|null} aspect - Desired aspect ratio, 'null' means no aspect ratio, use width only
 * @param {Boolean} disableInventoryMLPhotoSort
 * @param {Boolean} useMediaDomain
 * @return {Object} object of Photo URLs and photo types
 */
export function getPhotoConfig(inventory, width, aspect, disableInventoryMLPhotoSort = false, useMediaDomain = false) {
  let photoUrls = [];
  let photoType;

  const vin = get(inventory, 'vin', '');
  const dealerPhotoCount = get(inventory, 'vehicleInfo.photo.defaultPhoto.largePhotoCount', 0);
  const stockPhotos = get(inventory, DATA_PATH.STOCK_PHOTOS, []);
  const stockPhotoUrls = stockPhotos.map(({ url }) => url);
  const photoIndices = get(inventory, 'vehicleInfo.photo.validPhotoIndices', []);
  const validPhotoIndices = disableInventoryMLPhotoSort ? photoIndices.sort((a, b) => a - b) : photoIndices;
  const bodyType = get(inventory, 'vehicleInfo.styleInfo.bodyType', '');

  if (dealerPhotoCount) {
    photoUrls = times(dealerPhotoCount, index => getVinImage(vin, index + 1, width, aspect));
    photoType = PHOTO_TYPE.DEALER;

    if (validPhotoIndices.length) {
      photoUrls = validPhotoIndices.map(index => getVinImage(vin, index, width, aspect));
    }
  } else if (stockPhotoUrls.length) {
    photoUrls = stockPhotoUrls;
    photoType = getPhotoProvider(inventory) || PHOTO_TYPE.DEFAULT;
  }
  const hasPhotos = !!photoUrls.length;
  const noVehicleImage = getNoVehicleImageToDisplay(bodyType);

  return {
    photoUrls: hasPhotos ? photoUrls.map(useMediaDomain ? getMediaImageUrl : getEdmundsImageUrl) : [noVehicleImage],
    photoType: hasPhotos ? photoType : PHOTO_TYPE.NO_IMAGE,
    hasPhotos,
    photoUrlsWithPhotoTypes: hasPhotos
      ? photoUrls.map((url, index) =>
          getPhotoUrlsWithPhotoTypes({
            url,
            index,
            dealerPhotoCount,
            stockPhotoUrls,
            inventory,
            hasPhotos,
          })
        )
      : [{ url: noVehicleImage }],
  };
}

export function getAspectRatio(heroPhotoUrl, defaultAspectRatio) {
  const { originalWidth, originalHeight } = heroPhotoUrl;
  return !!originalWidth && !!originalHeight && originalWidth >= originalHeight
    ? `${originalWidth}:${originalHeight}`
    : defaultAspectRatio;
}
