import { get } from 'lodash';
import { ASPECT, getVinImage } from 'client/utils/inventory-image-helper';
import {
  getPhotoConfig,
  DEFAULT_WIDTH,
  getPhotoProvider,
} from 'client/site-modules/shared/utils/inventory-image-utils';
import { PHOTO_TYPE } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { NO_VEHICLE_IMAGE } from 'site-modules/shared/components/core-img/core-img';
import { getEdmundsImageUrl } from 'client/utils/image-helpers';

export const IMAGE_ASPECT = get(ASPECT, '_16x7');
const USURP_IMAGE_ASPECT = get(ASPECT, '_3x2');
export const IMAGE_WIDTH = 400;
export const IMAGE_INDEX = 1;

/**
 * Gets dealer/stock images for card, if no one exist - returns 'no vehicle' image.
 * @param {object} inventory
 * @param {Boolean} disableInventoryMLPhotoSort
 * @returns {{photoUrls: string[], hasPhotos: boolean}}
 */
export function getUsurpImages(inventory, disableInventoryMLPhotoSort = false) {
  return getPhotoConfig(inventory, DEFAULT_WIDTH, USURP_IMAGE_ASPECT, disableInventoryMLPhotoSort);
}

/**
 * Gets photo for primaryImageOfPage SEO property. EVOX is preferred, else first existing photo is returned.
 * @param {array} inventories
 * @returns {string}
 */
export function getPrimaryImageOfPage(inventories) {
  let primaryImageOfPage;
  const vinWithEvoxPhoto = inventories.find(vin => getPhotoProvider(vin) === PHOTO_TYPE.EVOX);
  const vinWithPhotos = inventories.find(vin => get(vin, 'vehicleInfo.photo.defaultPhoto.largePhotoCount', 0) > 0);

  if (vinWithEvoxPhoto) {
    primaryImageOfPage = get(vinWithEvoxPhoto, 'stockPhotoInfo.photos[0].url', '');
  } else if (vinWithPhotos) {
    primaryImageOfPage = getVinImage(vinWithPhotos.vin, IMAGE_INDEX, IMAGE_WIDTH, IMAGE_ASPECT);
  }

  // disabling eslint on the next line since we will hopefully be getting rid of this feature flag soon
  // eslint-disable-next-line no-nested-ternary
  return primaryImageOfPage ? getEdmundsImageUrl(primaryImageOfPage) : NO_VEHICLE_IMAGE;
}
