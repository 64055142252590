import React, { Component } from 'react';
import { reduce } from 'lodash';

/**
 * formValidation responsible for fields validation by passed validators.
 * @param {ReactComponent} WrappedComponent
 * @param {object} validators - Validation rules for fields.
 * @returns {FormValidation}
 */
export function formValidation(WrappedComponent, validators) {
  // todo: validators object check

  return class FormValidation extends Component {
    state = {
      validationErrors: {},
      isFormValid: false,
    };

    getErrorText = (validator, value) =>
      typeof validator.errorText === 'function' ? validator.errorText(value) : validator.errorText;

    getFieldValue = fieldName => {
      const field = this.fields[fieldName];
      return typeof field.value === 'function' ? field.value() : field.value;
    };

    setFieldInvalid = (fieldName, customError) => {
      const validator = validators[fieldName];
      const value = this.getFieldValue(fieldName);

      const validationErrors = {
        ...this.state.validationErrors,
        [fieldName]: customError || this.getErrorText(validator, value),
      };

      this.setState({
        validationErrors,
      });
    };

    setFieldsInvalid = fieldNames => {
      const validationErrors = fieldNames.reduce((errors, fieldName) => {
        const validator = validators[fieldName];
        const value = this.getFieldValue(fieldName);
        return {
          ...errors,
          [fieldName]: this.getErrorText(validator, value),
        };
      }, {});
      this.setState({
        validationErrors,
      });
    };

    setFormOptional = (isOptional = true) => {
      this.isFormOptional = isOptional;
    };

    fieldsOrder = [];
    fields = {};
    fieldsValid = {};

    validate = (...args) => {
      // means single field validation, usually called from onBlur input handler
      // no args means validate all fields
      const isValid = args.length ? !this.validateField(...args) : this.validateForm();

      // form is valid only when all fields are valid
      this.setState({
        isFormValid: this.isFormOptional
          ? Object.values(this.fieldsValid).some(isFieldValid => isFieldValid)
          : Object.values(this.fieldsValid).every(isFieldValid => isFieldValid),
      });

      return isValid;
    };

    validateField = (event, fieldName) => {
      const validator = validators[fieldName];

      if (!(this.fields[fieldName] && validator && typeof validator.test === 'function')) return '';

      const value = this.getFieldValue(fieldName);
      const isValid = validator.isOptional && !value ? true : validator.test(value, validator.minLength);
      const validationError = isValid ? '' : this.getErrorText(validator, value);

      let validationErrors;
      if (isValid) {
        if (this.state.validationErrors[fieldName]) {
          const { [fieldName]: omit, ...restValidationErrors } = this.state.validationErrors;
          validationErrors = restValidationErrors;
        }
      } else {
        validationErrors = {
          ...this.state.validationErrors,
          [fieldName]: validationError,
        };
      }
      this.fieldsValid[fieldName] = isValid;

      if (validationErrors) {
        this.setState({
          validationErrors,
        });
      }

      return validationError;
    };

    validateForm = () => {
      let isValid = true;

      const validationErrors = this.fieldsOrder.reduce((errors, fieldName) => {
        const validationError = this.validateField(null, fieldName);
        if (validationError) {
          isValid = false;
          return {
            ...errors,
            [fieldName]: validationError,
          };
        }
        return errors;
      }, {});

      this.setState({
        validationErrors,
      });

      return isValid;
    };

    /**
     * Validates an email asynchronously
     * Disclaimer: each async validation has different response API
     * so it's hard to create reusable method for all combinations of fields.
     * It also depends on a field name, which makes this task even harder.
     * Creating separate methods is much simpler solution which doesn't require an abstraction layer.
     * @param pageName
     * @param venomVersion
     * @param {string} fieldName
     */
    validateEmailAsync = (pageName, venomVersion, fieldName = 'email') => {
      const validator = validators[fieldName];
      const value = this.getFieldValue(fieldName);
      const formValues = reduce(
        this.fields,
        (result, field, key) => ({
          ...result,
          [key]: this.getFieldValue(key),
        }),
        {}
      );

      return validator
        .asyncTest(formValues, { pageName, venomVersion })
        .then(response => (response.isEmailValid ? response : Promise.reject(this.getErrorText(validator, value))))
        .catch(() => {
          this.setFieldInvalid(fieldName);
          return { isEmailValid: false };
        });
    };

    /**
     * Validates a phone number asynchronously
     * Disclaimer: each async validation has different response API
     * so it's hard to create reusable method for all combinations of fields.
     * It also depends on a field name, which makes this task even harder.
     * Creating separate methods is much simpler solution which doesn't require an abstraction layer.
     * @param pageName
     * @param venomVersion
     * @param {string} fieldName
     */
    validatePhoneAsync = (pageName, venomVersion, fieldName = 'phoneNumber') => {
      const validator = validators[fieldName];
      const value = this.getFieldValue(fieldName);
      const formValues = reduce(
        this.fields,
        (result, field, key) => ({
          ...result,
          [key]: this.getFieldValue(key),
        }),
        {}
      );

      return validator
        .asyncTest(formValues, { pageName, venomVersion })
        .then(response => (response.isPhoneValid ? response : Promise.reject(this.getErrorText(validator, value))))
        .catch(() => {
          this.setFieldInvalid(fieldName);
          return { isPhoneValid: false };
        });
    };

    resetValidation = () => {
      this.setState({
        validationErrors: {},
      });
    };

    resetFields = () => {
      this.fields = {};
      this.fieldsValid = {};
      this.setFormOptional(false);
      this.setState({
        isFormValid: false,
      });
    };

    resetFieldValidation = (event, fieldName) => {
      let validationErrors;

      if (this.state.validationErrors[fieldName]) {
        const { [fieldName]: omit, ...restValidationErrors } = this.state.validationErrors;
        validationErrors = restValidationErrors;
      }

      if (validationErrors) {
        this.setState({
          validationErrors,
        });
      }
    };

    fieldRef = (fieldName, ref) => {
      this.fields[fieldName] = ref;
      this.fieldsValid[fieldName] = false;
      if (!this.fieldsOrder.includes(fieldName)) {
        this.fieldsOrder.push(fieldName);
      }
    };

    render() {
      const { validationErrors, isFormValid } = this.state;

      return (
        <WrappedComponent
          fields={this.fields}
          fieldsOrder={this.fieldsOrder}
          fieldRef={this.fieldRef}
          isFormValid={isFormValid}
          validationErrors={validationErrors}
          validate={this.validate}
          validateEmailAsync={this.validateEmailAsync}
          validatePhoneAsync={this.validatePhoneAsync}
          resetValidation={this.resetValidation}
          resetFields={this.resetFields}
          setFieldInvalid={this.setFieldInvalid}
          setFieldsInvalid={this.setFieldsInvalid}
          setFormOptional={this.setFormOptional}
          resetFieldValidation={this.resetFieldValidation}
          {...this.props}
        />
      );
    }
  };
}
