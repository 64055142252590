export const ENTER_KEY_VALUE = 'Enter';
export const SPACE_KEY_VALUE = ' '; // a single space.
export const ESCAPE_KEY_VALUE = 'Escape';
export const ARROW_DOWN_KEY_VALUE = 'ArrowDown';
export const ARROW_UP_KEY_VALUE = 'ArrowUp';
export const ARROW_LEFT_KEY_VALUE = 'ArrowLeft';
export const ARROW_RIGHT_KEY_VALUE = 'ArrowRight';
export const TAB_KEY_VALUE = 'Tab';
export const HOME_KEY_VALUE = 'Home';
export const END_KEY_VALUE = 'End';
export const PAGE_UP_KEY_VALUE = 'PageUp';
export const PAGE_DOWN_KEY_VALUE = 'PageDown';
export const DELETE_KEY_VALUE = 'Delete';
export const BACKSPACE_KEY_VALUE = 'Backspace';
