import { getIncentivesSum } from 'site-modules/shared/utils/inventory/price-analysis';
import { getCashByTermsRange } from 'site-modules/shared/utils/car-buying/get-cash-by-terms-range';

export const getTotalRebates = ({ incentives, isNonTMVDealer, showLeasePayments }) => {
  const leaseIncentives = isNonTMVDealer ? incentives?.filter(incentive => incentive.conditionCategory) : incentives;
  const loanIncentives = isNonTMVDealer ? incentives?.filter(incentive => !incentive.primary) : incentives;

  return showLeasePayments
    ? leaseIncentives.reduce((sum, incentive) => sum + getCashByTermsRange(incentive), 0)
    : getIncentivesSum(loanIncentives);
};
