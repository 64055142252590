import { logger } from 'client/utils/isomorphic-logger';
import { get, zipObject, flatMap } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { formatPhotosURL } from 'client/data/utils/format-urls';
import { stringifyQuotes, parseQuotes } from 'site-modules/shared/utils/string';
import { VehicleModel, buildActiveVehiclePath } from './vehicle';

const DEFAULT_ARRAY = [];

// "Grigio Chiaro" is light gray
export const COLOR_MAP = {
  silver: '*Silver*,*silver*,*Grigio Chiaro*',
};

export function buildMakeModelYearMediumEvoxPhotoPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].photos.mediumEvoxPhoto`;
}

export function buildSubmodelEvoxPhotoPath({ make, model, submodel, year }) {
  return `makes["${make}"].models["${model}"].submodels["${
    submodel ? submodel.replace(/\s/g, '%20') : submodel
  }"].years["${year}"].photos.evox`;
}

export function buildSubmodelEvoxTDS3Path({ make, model, submodel, year }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel.replace(
    /\s/g,
    '%20'
  )}"].years["${year}"].photos.evox.tds3`;
}

export function buildPricingPhotosPath({ makeSlug, modelSlug, year, submodelName }) {
  return `makes["${makeSlug}"].models["${modelSlug}"].years["${year}"].submodel["${submodelName}"].photos.pricing`;
}

export function buildMMYSubmodelTrimPhotosPathWithParams({
  make,
  model,
  year,
  submodel = 'default',
  trim = 'default',
  provider = 'OEM',
  photoprovider = 'default',
  shottype = 'FQ',
  width = '300',
  category = 'exterior',
  pageSize = '1',
}) {
  if (!make || !model || !year) {
    return null;
  }

  return `makes["${make}"].models["${model}"].years["${year}"].submodels["${submodel}"].trims["${stringifyQuotes(
    trim
  )}"].provider["${provider}"].photoprovider["${photoprovider}"].category["${category}"].shottype["${shottype}"].width["${width}"].pageSize["${pageSize}"]`;
}

export function buildSubmodelName(make, submodel) {
  if (make && submodel) {
    return `${make}+${submodel}`;
  }
  return '';
}

export function buildStyleIdsShotTypeColoredPhotosPaths(styleIds, provider, shottype, width, color) {
  return `styleIds["${styleIds}"].provider["${provider}"].shottype["${shottype}"].width["${width}"].color["${color}"]`;
}

export function buildStyleIdsShotTypePhotosPaths(styleIds, provider, shottype, width) {
  return `styleIds["${styleIds}"].provider["${provider}"].shottype["${shottype}"].width["${width}"]`;
}

export function buildMMYShotTypePhotosPaths({ make, model, year, shottype, width }) {
  return `makes["${make}"].models["${model}"].years["${year}"].shottype["${shottype}"].width["${width}"]`;
}

export function buildStockListingPhotosPath({ year, styleId, submodel, color }) {
  return `stockListing.years["${year}"].styleIds["${styleId}"].submodels["${submodel}"].colors["${color}"]`;
}

function simplifyPhotoObj(photo) {
  return {
    href: get(photo, 'sources[0].link.href', null),
    title: get(photo, 'title', null),
    color: get(photo, 'color', null),
    provider: get(photo, 'provider', null),
    shotTypeAbbreviation: get(photo, 'shotTypeAbbreviation', null),
    styleIds: get(photo, 'styleIds', []).reduce((styleIdsObj, styleId) => {
      styleIdsObj[styleId] = true; // eslint-disable-line no-param-reassign
      return styleIdsObj;
    }, {}),
    submodels: get(photo, 'submodels', []).reduce((submodels, submodel) => {
      submodels[submodel] = true; // eslint-disable-line no-param-reassign
      return submodels;
    }, {}),
  };
}

export const PhotosModel = createModelSegment('photos', [
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].photos.mediumEvoxPhoto',
    resolve(match, context) {
      const { makeSlug, modelSlug, year } = match;
      const url = `/media/v2/${makeSlug}/${modelSlug}/${year}/photos/?pagesize=1&width=500&shottype=TDS2%2CS&sortby=shottype%3AASC&category=exterior&photoprovider=EVOX%2COEM&provider=EVOX%2COEM`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ photos }) => get(photos, '[0].sources[0].link.href', ''));
    },
  },
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].submodel["{submodel}"].photos.pricing',
    async resolve(match, context) {
      const { makeSlug, modelSlug, year, submodel } = match;
      const url = `/media/v2/${makeSlug}/${modelSlug}/${year}/photos?color=*White*,*Silver*&provider=EVOX&submodel=${submodel}&shottype=TDS&width=500&pageSize=1&sortby=color%3Adesc&photoprovider=EVOX&pageNum=1`;
      const { photos } = await withMetrics(EdmundsAPI, context).fetchJson(url);
      return get(photos, '[0].sources[0].link.href', '');
    },
  },
  /**
   * For Submodel Selector Primary Photos - Width is 500. no submodel only needs MMY
   * photos by make model year grouped by submodel and used for submodel selector
   * makes["honda"].models["accord"].years["2017"].photos.evox
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].photos.evox',
    resolve(match, context) {
      const { make, model, year } = match;
      const url = `/media/v2/${make}/${model}/${year}/photos/groupby?grouplimit=1&width=500&pageSize=50&provider=EVOX%2COEM&shottype=TDS2%2CS%2CFQ&category=exterior&groupsortorder=ASC&groupfield=submodelName&photoprovider=EVOX%2COEM&groupsortfield=categoryArticleType`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ photos }) => photos.map(photo => photo.sources[0].link.href));
    },
  },
  /**
   * For Homepage Return Dashboard - Return photos for given make-model-year
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].inventoryType["{useIn}"].photos.evox',
    async resolve(match, context) {
      const { make, model, useIn } = match;
      let { year } = match;
      const currentYear = new Date().getFullYear();
      if (year === 'null') {
        try {
          const activeVehicle = await context.resolveValue(buildActiveVehiclePath({ make, model }), VehicleModel);
          year = get(activeVehicle, 'year');
        } catch (e) {
          year = currentYear;
        }
      }

      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].photos.evox`)
        .then(photos => ({ photo: photos[0], make, model, useIn, year }))
        .catch(() => {
          logger('warn', `Invalid parameters ${make} ${model} ${year}`);
          return [];
        });
    },
  },
  /**
   * For use with Submodel selector -  Width is 500. Used as fallback if MMY path does not return any photos.
   * makes["honda"].models["accord"].submodels["Accord+Sedan"].years["2017"].photos.evox
   * http://www.edmunds.com/api/media/v2/honda/accord/2017/photos?shottype=TDS2%2CS&sortby=photoprovider%3AASC&category=exterior&submodel=Accord+Sedan&width=500&photoprovider=EVOX%2COEM&provider=EVOX%2COEM
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].photos.evox',
    resolve(match, context) {
      const { make, model, year, submodel } = match;
      const url = `/media/v2/${make}/${model}/${year}/photos?&submodel=${submodel}&width=500&shottype=TDS2%2CS%2CFQ&sortby=photoprovider%3AASC&category=exterior&photoprovider=EVOX%2COEM&provider=EVOX%2COEM`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ photos }) => photos.map(photo => photo.sources[0].link.href))
        .catch(() => {
          logger('warn', `Invalid parameters ${make} ${model} ${year} ${submodel}`);
          return [];
        });
    },
  },
  /**
   * Pull TDS3 shots. Width is 500.
   * makes["honda"].models["accord"].submodels["Accord+Sedan"].years["2017"].photos.evox.tds3
   * http://www.edmunds.com/api/media/v2/honda/accord/2017/photos?shottype=TDS3%2CS&sortby=shottype%3AASC&category=exterior&submodel=Accord+Sedan&width=500&photoprovider=EVOX%2COEM&provider=EVOX%2COEM
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].photos.evox.tds3',
    resolve(match, context) {
      const { make, model, year, submodel } = match;
      const url = `/media/v2/${make}/${model}/${year}/photos?&submodel=${submodel}&width=500&shottype=TDS3%2CS%2CFQ&sortby=shottype%3AASC&category=exterior&photoprovider=EVOX%2COEM&provider=EVOX%2COEM`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ photos }) => photos.map(photo => photo.sources[0].link.href));
    },
  },
  /**
   * For use with Other Years - Width is 300. Used for get an array of pictures for provided years.
   * makes["jeep"].models["wrangler"].photos["{filteredYears}"]
   * @return {{ 2012: 'path-tophoto-2012', 2013: 'path-tophoto-2013', ... }} Object map with years as keys and year
   * photo paths as values.
   */
  {
    path: 'makes["{make}"].models["{model}"].photos["{years}"]',
    resolve(match, context) {
      const { make, model, years } = match;
      const yearArray = years.split(',');
      return Promise.all(
        yearArray.map(year => context.resolveValue(`makes["${make}"].models["${model}"].years["${year}"].photos.evox`))
      ).then(photos => zipObject(yearArray, flatMap(photos)));
    },
  },
  /**
   * Large Stock Photos (pageSize is always 10, pageNum is provided)
   * ex: http://www.edmunds.com/api/media/v3/honda/accord/2017/photos?width=1280&pageNum=2
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].stockPhotos.large.page[{pageNum}]',
    resolve(match, context) {
      const { makeSlug, modelSlug, year, pageNum } = match;
      const url = `/media/v3/${makeSlug}/${modelSlug}/${year}/photos?width=1280&pageNum=${pageNum}`;
      return withMetrics(EdmundsAPI, context).fetchJson(url);
    },
  },
  /**
   * Large Stock Photos With Submodel
   * ex: http://www.edmunds.com/api/media/v3/honda/accord/2017/photos?submodel=sedan&width=1280&pageNum=2
   */
  {
    path:
      'makes["{makeSlug}"].models["{modelSlug}"].submodels["{submodelSlug}"].years["{year}"].stockPhotos.large.page[{pageNum}]',
    resolve(match, context) {
      const { makeSlug, modelSlug, submodelSlug, year, pageNum } = match;
      const url = `/media/v3/${makeSlug}/${modelSlug}/${year}/photos?submodel=${submodelSlug}&width=1280&pageNum=${pageNum}`;
      return withMetrics(EdmundsAPI, context).fetchJson(url);
    },
  },
  {
    path: 'styleIds["{styleIds}"].provider["{provider}"].shottype["{shottype}"].width["{width}"]',
    resolve({ styleIds, provider, shottype, width }, context) {
      return withMetrics(EdmundsAPI, context).fetchJson(
        `/media/v2/styles/photos/groupby?matchstyle=true&styleids=${styleIds}&shottype=${shottype}&groupfield=yearMakeModelNiceNamed&view=custom,fieldsInclude:sources,link,href,styleIds,provider,title&width=${width}&photoprovider=${provider}`
      );
    },
  },
  {
    path: 'styleIds["{styleIds}"].provider["{provider}"].shottype["{shottype}"].width["{width}"].color["{color}"]',
    resolve({ styleIds, provider, shottype, width, color }, context) {
      return withMetrics(EdmundsAPI, context).fetchJson(
        `/media/v2/styles/photos/groupby?matchstyle=true&styleids=${styleIds}&shottype=${shottype}&groupfield=yearMakeModelNiceNamed&view=custom,fieldsInclude:sources,link,href,styleIds&width=${width}&photoprovider=${provider}&color=${color}&pagesize=20`
      );
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].shottype["{shottype}"].width["{width}"]',
    resolve({ make, model, year, shottype, width }, context) {
      const urlTest = formatPhotosURL({
        make,
        model,
        year,
        params: {
          shottype,
          width,
          pagesize: 1,
          category: 'exterior',
          photoprovider: 'EVOX,OEM',
        },
      });
      return withMetrics(EdmundsAPI, context)
        .fetchJson(urlTest)
        .then(({ photos }) => get(photos, '[0].sources[0].link.href'));
    },
  },

  /**
   * https://qa-11-www.edmunds.com/api/media/v2/stocklisting/photos/?&inv=year:2019,sub:G70%20Sedan,color:Havana%20Red,styleid:401754757&width=600&pageSize=300&height=263
   * @see buildStockListingPhotosPath
   * @return string - e.g. /genesis/g70/2019/evox/2019_genesis_g70_sedan_20t-advanced_tds_evox_3_600x263.jpg
   */
  {
    path: 'stockListing.years["{year}"].styleIds["{styleId}"].submodels["{submodel}"].colors["{color}"]',
    resolve(match, context) {
      const { year, styleId, submodel, color } = match;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `/media/v2/stocklisting/photos/?inv=year:${year},sub:${submodel},color:${color},styleid:${styleId}&width=600&height=263&pageSize=300`
        )
        .then(response => get(response, 'stylePhotoHolderDto[0].photoDtos[0].sources[0].link.href'));
    },
  },
  {
    path: 'styleIds["{styleIds}"].pubState["{pubState}"].stockPhotos.nearestColor["{color}"]',
    resolve(match, context) {
      const { styleIds, color, pubState } = match;
      const url = `/media/v2/styles/${styleIds}/stockphotos?color=${color.replace(
        / /g,
        '+'
      )}&pubState=${pubState}&width=600`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ photos }) => (photos || []).map(({ sources }) => sources[0].link.href))
        .catch(() => DEFAULT_ARRAY);
    },
  },

  /**
   * https://www.edmunds.com/api/media/v2/volvo/xc90/2019/photos?submodel=XC90%20Hybrid&trim=T8%20Inscription%20Twin%20Engine%20Plug-In%20Hybrid&provider=OEM&shottype=FQ&category=exterior&pageNum=1&width=300&pageSize=1
   * @see buildMMYSubmodelTrimPhotosPathWithParams
   */
  {
    path:
      'makes["{make}"].models["{model}"].years["{year}"].submodels["{submodel}"].trims["{trim}"].provider["{provider}"].photoprovider["{photoprovider}"].category["{category}"].shottype["{shottype}"].width["{width}"].pageSize["{pageSize}"]',
    resolve(
      { make, model, year, submodel, trim, provider, photoprovider, shottype, width, category, pageSize },
      context
    ) {
      const url = formatPhotosURL({
        make,
        model,
        year,
        params: {
          trim: trim === 'default' ? undefined : parseQuotes(trim),
          submodel: submodel === 'default' ? undefined : submodel,
          provider,
          photoprovider: photoprovider === 'default' ? undefined : photoprovider,
          shottype,
          width,
          category,
          pageSize,
        },
      });

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => get(response, 'photos', []).map(photo => simplifyPhotoObj(photo)))
        .catch(() => []);
    },
  },
]);
