import { get } from 'lodash';
import { EdmundsAPI } from 'client/data/api/api-client';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { logger } from 'client/utils/isomorphic-logger';
import { getMetricsHeaders } from 'client/utils/metrics-header';
import { SessionRecorder } from 'client/utils/session-recorder/session-recorder';

const EMAIL_PHONE_VALIDATION_URL = '/uservalidation/v1/validatecontact';

const LEAD_CANNOT_BE_REACHED = /^4$/;
const VALID_PHONE_TYPES = /^(Landline|Mobile|Non-fixed VOIP|Fixed VOIP|Unknown|Other)$/i;
const INVALID_PHONE_WARNING = /Invalid Input/i;

/**
 * Set of rules required to accept a phone number from a client
 * @param params
 * @returns {boolean}
 */
export function isPhoneValid(params) {
  const isValid = get(params, 'is_valid');
  const score = get(params, 'phone_contact_score', 4);
  const hasWrongWarning = get(params, 'warnings', []).some(warning => INVALID_PHONE_WARNING.test(warning));
  const isTypeValid = get(params, 'line_type', '');

  return !!(isValid && !hasWrongWarning && !LEAD_CANNOT_BE_REACHED.test(score) && VALID_PHONE_TYPES.test(isTypeValid));
}

/**
 * Set of rules required to accept an email from a client
 * @param params
 * @returns {boolean}
 */
export function isEmailValid(params) {
  const isValid = get(params, 'is_valid');
  const isDisposable = get(params, 'is_disposable');
  const score = get(params, 'email_contact_score', 4);

  return !!(isValid && !isDisposable && !LEAD_CANNOT_BE_REACHED.test(score));
}

/**
 * Prepares a map of required request options
 */
export function requestOptions({ firstName, lastName, phone, email }, options) {
  const name = `${firstName} ${lastName}`.trim();
  const { pageName, venomVersion } = options;
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...getMetricsHeaders('asyncValidation', pageName, venomVersion),
    },
    method: 'POST',
    body: objectToQueryString({ name, phone, email }),
  };
}

/**
 * Asynchronous email/phone validation by using validation-rest-web API
 * @param {string} [id=] Page (Component) identifier
 * @param {string} [firstName=]
 * @param {string} [lastName=]
 * @param {string} [phone=]
 * @param {string} [email=]
 */
function fetchEmailPhoneValidation({ id = '', firstName = '', lastName = '', phone = '', email = '' }, options = {}) {
  const reqOptions = requestOptions({ firstName, lastName, phone, email }, options);
  const isSessionRecorderActive = get(options, 'isSessionRecorderActive');

  return EdmundsAPI.fetchJson(`${EMAIL_PHONE_VALIDATION_URL}?id=${id}`, reqOptions)
    .then(response => {
      const result = {};

      if (phone) {
        result.isPhoneValid = isPhoneValid(get(response, 'whitepagesResult.phone_checks', {}));
      }
      if (email) {
        result.isEmailValid = isEmailValid(get(response, 'whitepagesResult.email_address_checks', {}));
      }
      return result;
    })
    .catch(error => {
      if (isSessionRecorderActive) {
        SessionRecorder.addTag('#error');
      }
      logger('error', error);
      return { isPhoneValid: true, isEmailValid: true };
    });
}

/**
 * Shortcut to validate only email
 */
function fetchEmailValidation({ id, firstName, lastName, email }, options) {
  return fetchEmailPhoneValidation({ id, firstName, lastName, email }, options);
}

/**
 * Shortcut to validate only phone
 */
function fetchPhoneValidation({ id, firstName, lastName, phone }, options) {
  return fetchEmailPhoneValidation({ id, firstName, lastName, phone }, options);
}

export const asyncValidation = {
  fetchEmailValidation,
  fetchPhoneValidation,
  fetchEmailPhoneValidation,
};
