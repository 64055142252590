/* eslint-disable no-underscore-dangle */

import { isArray, get } from 'lodash';
import { isNode } from 'client/utils/environment';
import { getQuery } from 'client/utils/location';
import { ClientConfig } from 'client/configuration';

export const SessionRecorder = {
  track(wid, options) {
    window.__insp = window.__insp || [];

    if (window.__insp.wid === wid) {
      return;
    }

    window.__insp.push(['wid', wid]);

    if (options && options.debug) {
      window.__insp.push(['debug', true]);
    }

    if (options && options.tag) {
      SessionRecorder.addTag(options.tag);
    }

    if (document.readyState !== 'complete') {
      window.addEventListener('load', () => SessionRecorder.attach(wid), false);
    } else {
      SessionRecorder.attach(wid);
    }
  },

  attach(wid) {
    if (typeof window.__inspld !== 'undefined') {
      return;
    }

    window.__inspld = 1;
    const insp = document.createElement('script');
    const rand = Math.floor(Date.now() / 3600000);

    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = 'inspsync';
    insp.src = `//cdn.inspectlet.com/inspectlet.js?wid=${wid}&r=${rand}`;

    /* istanbul ignore else: (Else case never exists) */
    if (document.head) {
      document.head.appendChild(insp);
    }
  },

  addTag(tags) {
    if (!isNode() && window.__insp) {
      if (isArray(tags)) {
        tags.forEach(tag => window.__insp.push(['tagSession', tag]));
      } else {
        window.__insp.push(['tagSession', tags]);
      }
    }
  },

  /* https://docs.inspectlet.com/hc/en-us/articles/219801308-Manually-Triggering-a-New-Session */
  reset() {
    if (!isNode() && window.__insp) {
      window.__insp.push(['resetSession']);
    }
  },

  getInspectletUrl() {
    if (!isNode() && window.__insp) {
      return `https://www.inspectlet.com/dashboard/watchsession/${window.__insp.wid}/${window.__insp.sid}`;
    }

    return '';
  },

  /**
   * @param {Object} location Will be inspected whether it contains the `?forceSessionRecorder` query param,
   *   which forces activation.
   * @param {Object} options
   * @param {boolean} options.enableE2eDetection enables whether to check if e2e=true query param is present. Records
   *   session regardless of e2e param if false.
   */
  isActive(location = {}, moduleConfigKey, options = {}) {
    const { forceSessionRecorder, e2e } = getQuery(location);
    const { enableE2eDetection = false } = options;

    return (
      (forceSessionRecorder !== undefined ||
        !get(ClientConfig.get(moduleConfigKey), 'sessionRecorder.disabled', false)) &&
      !(enableE2eDetection && e2e)
    );
  },
};
