import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import { Row, Col } from 'reactstrap';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import classnames from 'classnames';

export const INCENTIVES_SECTIONS = {
  restrictions: 'Requirements and Restrictions:',
  bonus: 'Bonus',
  start: 'Start',
  end: 'End',
};

/**
 * Renders tooltip info for incentive.
 * @param {string} restrictions
 * @param {number} [startDate]
 * @param {number} [endDate]
 * @param {number} [rebateAmount]
 * @param {boolean} [isVdp]
 * @returns {ReactElement}
 */
export function IncentiveTooltipInfo({ restrictions, startDate, endDate, rebateAmount, isVdp }) {
  const startedDate = new Date(startDate);
  const expiredDate = new Date(endDate);
  const { restrictions: restrictionsLabel, bonus, start, end } = INCENTIVES_SECTIONS;

  return (
    <div>
      <dl className={classnames('mb-0', { disclaimer: !isVdp })}>
        <dt className="mb-0_5 text-gray-darker">{restrictionsLabel}</dt>
        <dd className="text-gray-darker">{restrictions}</dd>
      </dl>
      {rebateAmount || startDate || endDate ? (
        <Row className={classnames({ xsmall: !isVdp })}>
          {rebateAmount ? (
            <Col className="d-flex flex-column">
              <strong>{bonus}</strong>
              <span>{formatPriceString(rebateAmount)}</span>
            </Col>
          ) : null}
          {startDate ? (
            <Col className="d-flex flex-column">
              <strong>{start}</strong>
              <span className={classnames({ xsmall: !isVdp })}>{dateFormat(startedDate, 'mm/dd/yyyy')}</span>
            </Col>
          ) : null}
          {endDate ? (
            <Col className="d-flex flex-column">
              <strong>{end}</strong>
              <span>{dateFormat(expiredDate, 'mm/dd/yyyy')}</span>
            </Col>
          ) : null}
        </Row>
      ) : null}
    </div>
  );
}

IncentiveTooltipInfo.propTypes = {
  endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rebateAmount: PropTypes.number,
  restrictions: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isVdp: PropTypes.bool,
};

IncentiveTooltipInfo.defaultProps = {
  endDate: null,
  rebateAmount: null,
  startDate: null,
  isVdp: false,
};
