import { get } from 'lodash';
import { EdmundsAPI } from 'client/data/api/api-client';
import { logger } from 'client/utils/isomorphic-logger';

export const getMPGByStyle = style =>
  EdmundsAPI.fetchJson(
    `/vehicle/v3/styles?id=${
      style.id
    }&fields=attributeGroups.SPECIFICATIONS.properties.EPA_CITY_MPG,attributeGroups.SPECIFICATIONS.properties.EPA_HIGHWAY_MPG,attributeGroups.SPECIFICATIONS.properties.EPA_COMBINED_MPG`
  )
    .then(data => get(data, 'results[0].attributeGroups.SPECIFICATIONS.properties.EPA_COMBINED_MPG'))
    .catch(e => {
      logger('error', `Not able to fetch MPG for style - ${style.id}. Error - ${e}`);
    });

/**
 * Returns basic lease payment api url.
 * @param {number} salesPrice
 * @param {number} salesTaxRate
 * @param {number} fees
 * @param {number} cashIncentives
 * @param {number} downPayment
 * @param {number} tradeIn
 * @param {number} owedTradeIn
 * @param {number} moneyFactor
 * @param {number} term
 * @param {number} annualMileage
 * @param {number} residualValue
 * @param {number} acquisitionFee
 * @param {number} securityDeposit
 * @returns {string}
 */
export const getBasicLeasePaymentApiUrl = ({
  salesPrice,
  salesTaxRate = 0,
  fees,
  cashIncentives,
  downPayment,
  tradeIn,
  owedTradeIn,
  moneyFactor,
  term,
  annualMileage,
  residualValue,
  acquisitionFee,
  securityDeposit,
}) =>
  `/calculators/v2/basicleasepayment/?salesPrice=${salesPrice}&salesTaxRate=${salesTaxRate}&titleFees=${fees}&cashRebate=${cashIncentives}&downPayment=${downPayment}&tradeIn=${tradeIn}&tradeInOwedAmount=${owedTradeIn}&moneyFactor=${moneyFactor}&numberOfMonths=${term}&milesPerYear=${annualMileage}&residualValuePercentage=${residualValue}&acquisitionFee=${acquisitionFee}&securityDeposit=${securityDeposit}`;

/**
 * Returns loan payment api url.
 * @param {number} salesPrice
 * @param {number} term
 * @param {number} downPayment
 * @param {number} tradeIn
 * @param {number} titleFees
 * @param {number} salesTaxRate
 * @param {number} owedTradeIn
 * @param {number} cashIncentives
 * @param {number} interestRate
 * @returns {string}
 */
export const getLoanPaymentApiUrl = ({
  salesPrice,
  term = 60,
  downPayment = 0,
  tradeIn = 0,
  titleFees = 0,
  salesTaxRate = 0,
  owedTradeIn = 0,
  cashIncentives = 0,
  interestRate = 0,
}) =>
  `/calculators/v2/loanpayment/?salesPrice=${salesPrice}&numberOfMonths=${term}&downPayment=${downPayment}&financeRate=${interestRate}&tradeIn=${tradeIn}&titleFees=${titleFees}&salesTaxRate=${salesTaxRate}&tradeInOwedAmount=${owedTradeIn}&conditionalIncentiveAmount=${cashIncentives}&withTaxesAndFees=false&applyLimits=false`;
