import { get } from 'lodash';

const CAR_AGE = {
  NEW: 'New',
  CPO: 'NearNew',
  USED: 'Used',
  PRE_PROD: 'PreProd',
  noPreference: 'NoPreference',
};

export function formatCarAge(carAge) {
  return CAR_AGE[carAge] || CAR_AGE.noPreference;
}

function formatMakeModel(styleInfo, type, modelLinkCode) {
  const carAge = formatCarAge(type);
  const { make, model, year, subModels = [] } = styleInfo;
  const subModel = subModels.length && subModels[0];
  const makeModel = {
    carAge: [carAge],
    make,
    model,
    year,
  };

  if (subModel) {
    makeModel.subModel = subModel.identifier;
  }

  if (modelLinkCode) {
    makeModel.modelLinkCode = modelLinkCode;
  }

  return makeModel;
}

function formatSaveSearchMakeModel(styleInfo, type, modelLinkCode) {
  const carAge = formatCarAge(type);
  const { make, model, subModels = [] } = styleInfo;
  const subModel = subModels.length && subModels[0];
  const makeModel = {
    carAge: [carAge],
    make,
    model,
  };

  if (subModel) {
    makeModel.subModel = subModel.identifier;
  }

  if (modelLinkCode) {
    makeModel.modelLinkCode = modelLinkCode;
  }

  return makeModel;
}

export function formatMergedAttributes(vehicle) {
  const { vehicleInfo, type, modelLinkCode } = vehicle;
  const { styleInfo, partsInfo = {}, vehicleColors } = vehicleInfo;

  const cylinders = parseInt(partsInfo.cylinders, 10);
  const makeModel = formatMakeModel(styleInfo, type, modelLinkCode);

  const dataToSave = {
    makeModels: [makeModel],
  };

  const extColor = get(vehicleColors, 'exterior.genericName');
  if (extColor) {
    dataToSave.extColor = [extColor];
  }

  if (partsInfo.drivetrain) {
    dataToSave.drivetrain = [partsInfo.drivetrain];
  }

  if (partsInfo.transmission) {
    dataToSave.transmission = [partsInfo.transmission];
  }

  if (styleInfo.bodyType) {
    dataToSave.bodyType = [styleInfo.bodyType];
  }

  // Sometimes cylinders' value is "null-cylinders"
  if (!isNaN(cylinders)) {
    dataToSave.cylinders = [cylinders];
  }

  return {
    mergedAttributes: dataToSave,
  };
}

/**
 * get mergedAttributes for saveSearch.
 * @param {Object} searchResults
 * @param {String} modelLinkCode
 * @return {Object}
 */
export function formatMergedSaveSearchAttributes({ inventories, attributes }, modelLinkCode) {
  const { vehicleInfo, type } = (inventories && inventories.results && inventories.results[0]) || {};
  const { vehicle = {}, type: vehicleCategory } = attributes || {};
  const { make, model } = vehicle;
  const makeName = make && make.name;
  const modelName = model && model.name;
  const styleInfo = (vehicleInfo && vehicleInfo.styleInfo) || {
    make: makeName,
    model: modelName,
  };
  const dataToSave = {
    makeModels: [],
  };

  if (makeName || modelName) {
    dataToSave.makeModels = [formatSaveSearchMakeModel(styleInfo, type, modelLinkCode)];
  }

  if (vehicleCategory && type) {
    dataToSave.bodyType = [styleInfo.bodyType];
  }

  return {
    mergedAttributes: dataToSave,
  };
}
