import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI, UdmAPI } from 'client/data/api/api-client';
import { get, isEmpty, uniqBy } from 'lodash';
import { withMetrics } from 'client/data/api/api-metrics';
import { getVinsRequest } from 'client/data/models/profile/profile-utils';
import { VisitorModel } from './visitor';

const UDM_LOOKBACK_VALUE = 50;

export const UDM_FULL_HISTORY_PATH = 'history.size["25"]';

/**
 * removeUndefinedAggregate - Remove undefined items.
 * @param {Object} aggregates
 */
const removeUndefinedAggregate = aggregates => aggregates.filter(item => !!item);

/**
 * removeDuplicateModels - Removes all duplicate make model from modelAggregates.
 * @param {*} modelAggregates
 */
const removeDuplicateModels = modelAggregates =>
  uniqBy(removeUndefinedAggregate(modelAggregates), item => item.make + item.model + item.useIn);

/**
 * parseMakeModels - Simplifies all returned make model useIn values
 * @param {*} modelAggregates
 */
const parseMakeModels = modelAggregates =>
  modelAggregates.map(item => {
    const updatedItem = item;
    if (item.useIn === 'new_used') updatedItem.useIn = 'new';
    return updatedItem;
  });

/**
 * removeDuplicateVins - Removes all duplicate vin from vinAggregates.
 * @param {*} vinAggregates
 */
const removeDuplicateVins = vinAggregates => uniqBy(removeUndefinedAggregate(vinAggregates), 'vin');

const getAggregatedHistory = async (context, retries) => {
  const options = retries !== undefined ? { retries } : {};
  return context.resolveValue('id', VisitorModel).then(userId => {
    const url = `/cluster/userhistory/aggregatedstatistic?visitor-ids=${userId}&sort=latestTimeStamp&lookback=${UDM_LOOKBACK_VALUE}`;
    return UdmAPI.fetchJson(url, options);
  });
};

export const VisitorHistoryModel = createModelSegment('visitorHistory', [
  {
    path: 'history',
    resolve(match, context) {
      return context.resolveValue('id', VisitorModel).then(userId => {
        const url = `/visitor-history/v1/visitors/${userId}/history`;

        return withMetrics(EdmundsAPI, context).fetchJson(url);
      });
    },
  },
  {
    path: 'history.size["{size}"]',
    resolve({ size }, context) {
      return context.resolveValue('id', VisitorModel).then(userId => {
        const url = `/cluster/userhistory/${userId}?lookback=${size}`;

        return withMetrics(UdmAPI, context).fetchJson(url);
      });
    },
  },
  {
    path: 'aggregatedHistory',
    resolve(match, context) {
      return getAggregatedHistory(context);
    },
  },
  {
    path: 'aggregatedHistory.withoutRetries',
    resolve(match, context) {
      return getAggregatedHistory(context, 0);
    },
  },
  {
    path: 'aggregatedHistory.vinAndModelAggregates.size["{size}"].isVinOnly["{isVinOnly}"]',
    resolve(match, context) {
      const { size, isVinOnly } = match;

      return context
        .resolveValue(
          `aggregatedHistory.vinAndModelAggregates.size["${size}"].isVinOnly["${isVinOnly}"].retries["default"]`
        )
        .then(() => ({
          $ref: `#/aggregatedHistory/vinAndModelAggregates/size/${size}/isVinOnly/${isVinOnly}/retries/default`,
        }));
    },
  },
  {
    path: 'aggregatedHistory.vinAndModelAggregates.size["{size}"].isVinOnly["{isVinOnly}"].retries["{retries}"]',
    resolve(match, context) {
      const { size, isVinOnly, retries } = match;
      const aggregatedHistory =
        retries === 'default'
          ? context.resolveValue('aggregatedHistory')
          : context.resolveValue('aggregatedHistory.withoutRetries');

      return aggregatedHistory.then(async userHistory => {
        const maxSize = parseInt(size, 10);
        const { vinAggregates = [], modelAggregates = [] } = userHistory;
        const vinAggregatesNoDuplicates = removeDuplicateVins(vinAggregates)
          .map(item => item.vin)
          .slice(0, maxSize);

        let recentlyViewedVins = [];
        if (!isEmpty(vinAggregatesNoDuplicates)) {
          const location = await context.resolveValue('location', VisitorModel);
          const queryString = getVinsRequest({
            vin: vinAggregatesNoDuplicates,
            pagesize: vinAggregatesNoDuplicates.length,
            location,
          });

          const inventoryData = await withMetrics(EdmundsAPI, context)
            .fetchJson(`/purchasefunnel/v1/srp/inventory/?${queryString}`)
            .then(data => get(data, 'inventories.results', []));
          recentlyViewedVins = vinAggregatesNoDuplicates
            .map(recentlyViewedVin => inventoryData.find(({ vin }) => recentlyViewedVin === vin))
            .filter(value => value);
        }

        if (isVinOnly.match(/true|yes|1/)) return recentlyViewedVins;

        const parsedMakeModels = parseMakeModels(modelAggregates);
        const recentlyViewedMakeModels = removeDuplicateModels(parsedMakeModels).slice(0, maxSize);
        return { recentlyViewedVins, recentlyViewedMakeModels };
      });
    },
  },
]);
