import get from 'lodash/get';
import { IS_NODE } from 'client/utils/environment';

let storage = {};

export const SecretsManagerStorage = {
  load(obj = {}) {
    storage = {
      ...storage,
      ...obj,
    };
    return this;
  },

  save(obj = {}) {
    return {
      ...obj,
      ...storage,
    };
  },

  get(key, defaultValue) {
    return storage[key] || defaultValue;
  },
};

if (!IS_NODE) {
  // eslint-disable-next-line venom/no-prohibited-functions
  const secretsManager = get(window, '__PRELOADED_STATE__.secretsManager');
  if (secretsManager) {
    SecretsManagerStorage.load(secretsManager);
  }
}
