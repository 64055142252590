import PropTypes from 'prop-types';
import { isNil, pickBy } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { getLoanPaymentApiUrl } from 'site-modules/shared/utils/calculator/api';
import { STORAGE_KEYS } from 'site-modules/shared/constants/car-buying';
import { isNode } from 'client/utils/environment';
import { Storage } from 'client/site-modules/shared/utils/storage';

const loanPayment = PropTypes.shape({
  loanAmount: PropTypes.number,
  monthlyPayment: PropTypes.number,
  savingsAmount: PropTypes.number,
  calculationCriteria: PropTypes.shape({
    numberOfMonths: PropTypes.number,
    financeRate: PropTypes.number,
    downPayment: PropTypes.number,
    tradeIn: PropTypes.number,
    tradeInOwedAmount: PropTypes.number,
    salesTaxRate: PropTypes.number,
    salesPrice: PropTypes.number,
    titleFees: PropTypes.number,
    conditionalIncentiveAmount: PropTypes.number,
    docFee: PropTypes.number,
  }),
});

const leasePayment = PropTypes.shape({
  monthlyPayment: PropTypes.number,
  dueAtSigning: PropTypes.number,
  netCapCost: PropTypes.number,
  apr: PropTypes.number,
  paymentWithTaxes: PropTypes.shape({
    monthlyPayment: PropTypes.number,
    capitalizedFees: PropTypes.shape({
      titleFee: PropTypes.number,
      registrationFee: PropTypes.number,
      dmvFee: PropTypes.number,
      combinedFees: PropTypes.number,
      acquisitionFee: PropTypes.number,
      docFee: PropTypes.number,
    }),
    capitalizedTaxes: PropTypes.shape({
      citySalesTax: PropTypes.number,
      combinedSalesTax: PropTypes.number,
      countySalesTax: PropTypes.number,
      districtSalesTax: PropTypes.number,
      stateSalesTax: PropTypes.number,
      gasGuzzlerTax: PropTypes.number,
    }),
    amountFinanced: PropTypes.number,
    nonCapitalizedTaxesAndFees: PropTypes.number,
  }),
  paymentWithoutTaxes: PropTypes.shape({
    baseMonthlyPayment: PropTypes.number,
  }),
});

export const CalculatorsEntities = {
  loanPayment,
  leasePayment,
};

const LOAN_PREFERENCES = 'LOAN_PREFERENCES';
const LEASE_PREFERENCES = 'LEASE_PREFERENCES';

export const storage = new Storage('localStorage');

export const CALCULATORS_PATHS = {
  CALCULATOR_LEASE_SELECTIONS: `calculatorSelections["${LEASE_PREFERENCES}"]`,
  CALCULATOR_LOAN_SELECTIONS: `calculatorSelections["${LOAN_PREFERENCES}"]`,
  VISITED_TAB: 'digitalRetail.tab',
};

export const MODEL = {
  CALCULATOR_SELECTIONS: 'calculatorSelections["{type}"]',
  VISITED_TAB: 'digitalRetail.tab',
};

/**
 * Returns stored selection path based on `isPurchase` flag.
 * @param {boolean} isPurchase
 * @returns {string}
 */
export function getStoredSelectionsPath({ isPurchase }) {
  return isPurchase ? CALCULATORS_PATHS.CALCULATOR_LOAN_SELECTIONS : CALCULATORS_PATHS.CALCULATOR_LEASE_SELECTIONS;
}

export const CalculatorsModel = createModelSegment('calculators', [
  /**
   * Loan Payment API. Returns results that are based on passed data from `loanCriteria`.
   * Example: https://qa-21-www.edmunds.com/gateway/api/calculators/v2/loanpayment/?salesPrice=35000&numberOfMonths=72&downPayment=4000&financeRate=4.4&tradeIn=0&titleFees=0&salesTaxRate=8&tradeInOwedAmount=0&conditionalIncentiveAmount=0&withTaxesAndFees=false
   * @return CalculatorsEntities.loanPayment
   */
  {
    path: 'loanPayment',
    async resolve(match, context) {
      const loanCriteria = await context.resolveValue('loanCriteria');

      if (isNil(loanCriteria) || !loanCriteria.salesPrice) return null;

      try {
        const loanData = await withMetrics(EdmundsAPI, context).fetchJson(getLoanPaymentApiUrl(loanCriteria));

        return loanData;
      } catch (e) {
        return null;
      }
    },
  },
  /**
   * This criteria is used to collect data for loan payment request.
   */
  {
    path: 'loanCriteria',
  },
  /**
   * This criteria is used to store lease payment data.
   */
  {
    path: 'leasePayment',
  },
  /**
   * This criteria is used to collect data for lease payment request.
   */
  {
    path: 'leaseCriteria',
  },
  {
    path: MODEL.CALCULATOR_SELECTIONS,
    async resolve({ type }) {
      if (isNode()) {
        return Promise.resolve(undefined);
      }

      const storedSelections = storage.get(STORAGE_KEYS[type]);
      const selections = storedSelections || {};

      if (storedSelections && storedSelections.numberOfMonths) {
        selections.term = selections.numberOfMonths;
      }

      return Promise.resolve(pickBy(selections, value => !isNil(value)));
    },
    async update({ term, ...data }, { type }) {
      const dataToSave = {
        ...storage.get(STORAGE_KEYS[type]),
        ...data,
      };
      const numberOfMonths = term || dataToSave.numberOfMonths;

      storage.set(STORAGE_KEYS[type], {
        ...dataToSave,
        numberOfMonths,
      });

      return Promise.resolve(pickBy({ ...dataToSave, term: numberOfMonths, numberOfMonths }, value => !isNil(value)));
    },
  },
  { path: MODEL.VISITED_TAB },
]);
