import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

function fireAction(eventType, eventData) {
  EventToolbox.fireTrackAction({
    event_type: eventType,
    ads: {
      disable_refresh: true,
    },
    event_data: eventData,
  });
}

/**
 * Fires every time user authenticates successfully
 *
 * @param firebaseUID
 * @param creativeId
 */
export function fireFirebaseAuthAction(firebaseUID, creativeId) {
  if (!firebaseUID) return;

  const eventData = {
    action_category: 'system',
    action_cause: 'button_click',
    action_name: TrackingConstant.ACTION_AUTHENTICATE,
    subaction_name: TrackingConstant.AUTHENTICATE_FIREBASE,
    value: firebaseUID,
    creative_id: creativeId,
  };

  fireAction('action_completed', eventData);
}

/**
 * Fires every time anonymous user authenticates successfully
 *
 * @param firebaseUID
 * @param creativeId
 * @param trackingData - trackingData override or addition.
 */
export function fireFirebaseAnonymousAuthAction(firebaseUID, creativeId, trackingData) {
  if (!firebaseUID) return;

  const eventData = {
    action_category: 'user',
    action_cause: 'button_click',
    action_name: TrackingConstant.ACTION_SIGN_UP,
    subaction_name: TrackingConstant.SUCCESS_ANONYMOUS_SIGN_UP,
    value: firebaseUID,
    creative_id: creativeId,
    ...trackingData,
  };

  fireAction('action_end', eventData);
}

/**
 * Fires every time there is a firebase error.
 *
 * @param error - { code: String, message: String }
 * @param creativeId - String
 */
export function fireFirebaseErrorAction(error, creativeId) {
  const { code, message } = error;
  const eventData = {
    action_category: 'system',
    action_cause: 'button_click',
    action_name: TrackingConstant.ACTION_AUTHENTICATE,
    subaction_name: TrackingConstant.AUTHENTICATE_FIREBASE_ERROR,
    value: code ? `${code}:${message}` : 'unknown',
    creative_id: creativeId,
  };

  fireAction('action_completed', eventData);
}
