import React from 'react';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

export function FloodlightAd(props) {
  return (
    <div className="floodlight d-none fixed-bottom">
      <AdUnit
        adName="floodlight"
        position={'1'}
        nativeStyle="floodlight"
        all
        renderWhenViewable={false}
        refreshable={false}
        showLabel={false}
        refreshPageNavigationDisabled
        {...props}
      />
    </div>
  );
}
