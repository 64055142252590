import { get } from 'lodash';
import { ClientConfig } from 'client/configuration';
import { SecretsManagerStorage } from 'client/secrets-manager-storage';
import { logger } from 'client/utils/isomorphic-logger';
import {
  fireFirebaseAuthAction,
  fireFirebaseErrorAction,
} from 'client/engagement-handlers/firebase-engagement-handler/firebase-engagement-handler';
import { PROFILE_TRACKING_CONSTANTS } from 'client/tracking/profile-tracking-constants';
import { IS_NODE } from 'client/utils/environment';
// eslint-disable-next-line venom/no-venom-api-calls
import { VenomApi } from 'client/data/api/api-client';
import { SECRETS_MANAGER_FIREBASE } from 'shared/constants/secrets-manager';

export const firebase = {
  instance: null,
  app: null,
};

export const dummyFirebaseInstance = {
  auth: () => ({
    currentUser: {},
    onAuthStateChanged: () => {},
    signInAnonymously: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
  }),
};

const RECAPTCHA_CONFIG_KEY = 'recaptcha';

const COMMON_OPTIONS = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  showAPIError: true,
  retries: 0,
  timeout: 0,
};
/**
 * Use credentials/configs from secretsManager.
 */
export function firebaseCredentials() {
  return SecretsManagerStorage.get(SECRETS_MANAGER_FIREBASE.name) || ClientConfig.get(SECRETS_MANAGER_FIREBASE.name);
}

/**
 * Use credentials/configs from secretsManager, if it exists.
 * Fallsback to ClientConfig "recaptcha". This is only for local dev when developers DOES NOT
 * have AWS ACCOUNT or AWS ACCOUNT without read access.
 */
export function recaptchaCredentials() {
  return SecretsManagerStorage.get(RECAPTCHA_CONFIG_KEY) || ClientConfig.get(RECAPTCHA_CONFIG_KEY);
}

/**
 * Firebase initialization
 * calls only in case when firebase has no initialized apps
 * @returns {Object} promise.
 */
export const init = function init() {
  if (firebase.instance) {
    return Promise.resolve(firebase.instance);
  }

  if (IS_NODE) {
    firebase.instance = dummyFirebaseInstance;
    return Promise.resolve(firebase.instance);
  }

  // eslint-disable-next-line venom/no-prohibited-functions
  const insiderEnabled = get(window, '__PRELOADED_STATE__.featureFlags.insider', true);
  if (!insiderEnabled) {
    firebase.instance = dummyFirebaseInstance;
    return Promise.resolve(firebase.instance);
  }

  return import(/* webpackChunkName: "firebase-dependencies" */ './firebase-dependencies').then(dependencies => {
    firebase.app = dependencies.firebase;
    if (!firebase.app.apps.length) {
      firebase.instance = firebase.app.initializeApp(firebaseCredentials());
    }
    return firebase.instance;
  });
};

/**
 * Gets firebase auth instance on page load
 * @returns {Object} promise.
 */
export const authPromise = () => init().then(instance => instance.auth());

/**
 * Gets firebase auth instance
 * @returns {Object} auth instance.
 */
export const auth = () => firebase.instance.auth();

/**
 * Gets firebase auth provider (facebook and google).
 * @returns {Object} auth provider.
 */
export const authProvider = () => firebase.app.auth;

/**
 * Gets firebase current user
 * @returns {Object} Promise.
 */
export const getCurrentUser = () => authPromise().then(fbAuth => fbAuth.currentUser);

const createFirebaseProvider = (providerName, profileScope, providerId) => {
  const authProviders = authProvider();
  const FirebaseProvider = authProviders[providerName];
  const provider = new FirebaseProvider(providerId);
  profileScope.forEach(scope => provider.addScope(scope));

  return provider;
};

/**
 * Common handler (with error logger) for firebase promise rejection.
 * @returns {Object} Promise.
 */
export const onReject = (error, creativeId) => {
  logger('error', error.message);
  fireFirebaseErrorAction(error, creativeId);
  return Promise.reject(error);
};

/**
 * Fires tracking event on user sign in
 */
export const fireFirebaseTracking = creativeId =>
  fireFirebaseAuthAction(auth().currentUser.uid, PROFILE_TRACKING_CONSTANTS[creativeId]);

/**
 * Signs in user in firebase with social networks (facebook, google)
 * @param {String} providerName - provider name (FacebookAuthProvider, GoogleAuthProvider).
 * @param {Array} profileScope - list of requested permissions (public profile, email etc).
 * @returns {Object} Promise.
 */
export const socialSignIn = (providerName, profileScope, providerId) => {
  const provider = createFirebaseProvider(providerName, profileScope, providerId);
  return auth()
    .signInWithPopup(provider)
    .then(({ user }) => {
      fireFirebaseTracking('SIGN_IN_SOCIAL_CREATIVE_ID');
      return user;
    })
    .catch(error => onReject(error, 'SIGN_IN_SOCIAL_CREATIVE_ID'));
  // .catch(mergeCredentials) // TODO: Implement mergeCredential if needed
};

/**
 * Verify recaptcha
 */
const getSkipStatus = (skip, disableRecaptcha) => ({
  skip: skip || disableRecaptcha ? true : undefined,
});

export const verify = ({ email, password, token, skip, disableRecaptcha }) => {
  const options = {
    ...COMMON_OPTIONS,
    body: JSON.stringify({ action: 'verify', email, password, token, ...getSkipStatus(skip, disableRecaptcha) }),
  };
  // eslint-disable-next-line venom/no-venom-api-calls
  return VenomApi.fetch('/profile/action/', options);
};
/**
 * Signs in user in firebase with email and password
 */
export const signIn = ({ email, password, token, skip, disableRecaptcha }) =>
  verify({ email, password, token, skip, disableRecaptcha })
    .then(() => auth().signInWithEmailAndPassword(email, password))
    .then(() => fireFirebaseTracking('SIGN_IN_DRAWER_CREATIVE_ID'))
    .catch(error => onReject(error, 'SIGN_IN_DRAWER_CREATIVE_ID'));

/**
 * Registers new user and then signs in with email and password
 */
export const signUp = ({ email, password, token, disableRecaptcha }) =>
  verify({ email, password, token, disableRecaptcha })
    .then(() => auth().createUserWithEmailAndPassword(email, password))
    .then(({ user }) => {
      fireFirebaseTracking('SIGN_UP_DRAWER_CREATIVE_ID');

      if (!user.emailVerified) {
        user.sendEmailVerification();
      }
      return { email };
    })
    .catch(error => onReject(error, 'SIGN_UP_DRAWER_CREATIVE_ID'));

/**
 * Upgrade the user based off of email sign in
 * @param email - user's email
 * @param password - user's password
 * @param token - captcha
 * @param skip - skip captcha
 * @returns {Object} Promise
 */
export const upgradeByEmailPassword = async ({ email, password, token, skip, disableRecaptcha }) => {
  const idToken = await getCurrentUser().then(currentUser => currentUser && currentUser.getIdToken());
  const options = {
    ...COMMON_OPTIONS,
    body: JSON.stringify({
      action: 'upgradeSignUp',
      email,
      password,
      idToken,
      token,
      ...getSkipStatus(skip, disableRecaptcha),
    }),
  };

  // eslint-disable-next-line venom/no-venom-api-calls
  return VenomApi.fetchJson('/profile/action/', options)
    .then(() => auth().signInWithEmailAndPassword(email, password))
    .then(({ user }) => {
      if (!user.emailVerified) user.sendEmailVerification();
      fireFirebaseTracking('ANONYMOUS_ACCOUNT_UPGRADE_CREATIVE_ID');
      return user;
    })
    .catch(error => onReject(error, PROFILE_TRACKING_CONSTANTS.ANONYMOUS_ACCOUNT_UPGRADE_CREATIVE_ID));
};

/**
 * Sends reset password email with reset link
 * @param {Object} fields - email.
 * @param {Boolean} enableContinueUrl.
 * @returns {Object} Promise.
 */
export const resetPassword = (email, token, enableContinueUrl = true) => {
  const windowUrl = window.location.href;
  const { url } = ClientConfig.get('vanillaForums') || { url: '' };
  const redirectUrl = windowUrl.includes('vanillaSSO') ? url : windowUrl;

  const options = {
    ...COMMON_OPTIONS,
    body: JSON.stringify({ action: 'resetPassword', token, email, continueUrl: enableContinueUrl ? redirectUrl : '' }),
  };

  // eslint-disable-next-line venom/no-venom-api-calls
  return VenomApi.fetchJson('/profile/action/', options).catch(error => onReject(error, 'RESET_PASSWORD_CREATIVE_ID'));
};

/**
 * Watch user auth state and calls callback on state change
 * @param {Object} cb - callback.
 * @returns {Object} Promise.
 */
export const onAuthStateChanged = cb =>
  authPromise().then(fbAuth => {
    fbAuth.onAuthStateChanged(cb);
    return cb;
  });

/**
 * Signs out current user
 * @returns {Object} Promise.
 */
export const signOut = () => authPromise().then(fbAuth => fbAuth.signOut());

/**
 * Check oobCode for password reset or email confirmation.
 * @returns {Object} Promise.
 */
export const verifyActionCode = (actionCode, mode) => {
  const methodsMap = {
    resetPassword: 'verifyPasswordResetCode',
    verifyEmail: 'applyActionCode',
  };

  return authPromise().then(fbAuth => fbAuth[methodsMap[mode]](actionCode));
};

/**
 * Resets users's password.
 * @returns {Object} Promise.
 */
export const confirmPasswordReset = (actionCode, password) =>
  authPromise().then(fbAuth => fbAuth.confirmPasswordReset(actionCode, password));

/**
 * Confirms user password and changes
 * @param {string} email Email
 * @param {string} oldPassword Old password
 * @param {string} newPassword Valid new password
 * @return {Object} Promise.
 */
export const changePassword = (email, oldPassword, newPassword) => {
  const cred = authProvider().EmailAuthProvider.credential(email, oldPassword);

  return getCurrentUser()
    .then(user => user.reauthenticateWithCredential(cred).then(() => user.updatePassword(newPassword)))
    .catch(error => onReject(error, 'CHANGE_PASSWORD_CREATIVE_ID'));
};

/**
 * Reauthenticate with credentials
 * @param credential
 * @returns {Promise}
 */
export const reauthenticateWithCredential = (email, password) => {
  const credential = authProvider().EmailAuthProvider.credential(email, password);
  return getCurrentUser()
    .then(user => user.reauthenticateWithCredential(credential))
    .catch(error => onReject(error, 'REAUTHENTICATE_WITH_CREDENTIAL'));
};

/**
 * Reauthenticate with provider
 * @param credential
 * @returns {Promise}
 */
export const reauthenticateWithPopup = (providerName, profileScope, providerId) => {
  const provider = createFirebaseProvider(providerName, profileScope, providerId);
  return getCurrentUser()
    .then(user => user.reauthenticateWithPopup(provider))
    .catch(error => onReject(error, 'REAUTHENTICATE_WITH_POPUP'));
};

export const getEmailProvider = () =>
  get(auth(), 'currentUser.providerData', []).find(providerObj => providerObj.providerId === 'password');
