import { validation } from 'site-modules/shared/components/form-validation/validation';
import { asyncValidation } from 'site-modules/shared/components/form-validation/validation-async';

export const SMART_SIGN_SCREEN = {
  SIGN: 'sign',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
};
export const SIGN_EMAIL_ERROR = 'auth/email-already-in-use';

export const SMART_SIGN_VALID_EMAIL_EVENT = 'SMART_SIGN_VALID_EMAIL_EVENT';

// https://firebase.google.com/docs/auth/admin/manage-users
// https://cloud.google.com/identity-platform/docs/password-policy
export const PASSWORD_MAX_LENGTH = 4096;

const passwordValidator = {
  test: validation.validateMinLength,
  errorText: 'Your password must be at least 7 characters',
  minLength: 7,
};

export const validators = {
  email: {
    test: validation.validateEmail,
    asyncTest: (params, options) => asyncValidation.fetchEmailValidation({ ...params, id: 'insider' }, options),
    errorText: 'Please enter your correct email address',
  },
  passwordSignIn: {
    ...passwordValidator,
  },
  passwordSignUp: {
    ...passwordValidator,
    isOptional: true,
  },
};
