import {
  LEASE_INPUT_NAMES,
  LIMIT_DOWN_PAYMENT,
  LIMIT_TRADE_IN,
} from 'site-modules/shared/constants/calculator/calculator';
import { get, isString } from 'lodash';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { isNew } from 'client/site-modules/shared/utils/inventory-utils/is-new';

/**
 * Check key for `Enter` value from params.
 * @param {Object}
 * @returns {Boolean}
 */
export function isEnterPressed({ key }) {
  return key === 'Enter';
}

/**
 * Gets element params needed for recalculation
 * @param {HTMLElement} currentTarget
 * @returns {{value: number, name: string}}
 */
export function getRecalculationParams({ currentTarget }) {
  const { value, name } = currentTarget;
  const currentValue = isString(value) ? value.replace(',', '') : value;

  return {
    name: LEASE_INPUT_NAMES[name] || name,
    value: isNaN(currentValue) ? currentValue.replace(',', '') : Number(currentValue),
  };
}

/**
 * Generate messages for Calculator Limits
 * @param {{ limitPercentage: Number, limits: Array }} limitCriteria
 * @param {String} publicationState
 * @returns {{
 *   [paymentType]: 'Lenders typically accept down payments up to [limitPercentage]% of [MSRP or List] price: only [applied down payment amount] has been applied to monthly payment'
 * }}
 */
export function prepareLimitMessages(limitCriteria, publicationState) {
  if (!limitCriteria) {
    return {};
  }
  const limits = get(limitCriteria, 'limits', []);
  const limitPercentage = get(limitCriteria, 'limitPercentage', 0);
  const limitMessages = {};
  limits.forEach(({ paymentType, appliedAmount }) => {
    if (paymentType === LIMIT_TRADE_IN) {
      limitMessages[paymentType] = `${formatPriceString(
        appliedAmount
      )} from your trade-in is the maximum value we estimate can be applied to your deal, and equal to ${limitPercentage}% of the ${
        isNew(publicationState) ? 'MSRP.' : 'list price.'
      }`;
    }
    if (paymentType === LIMIT_DOWN_PAYMENT) {
      limitMessages[paymentType] = `Only ${formatPriceString(
        appliedAmount
      )} has been applied to the payment, which is the maximum allowed ${limitPercentage}% of the ${
        isNew(publicationState) ? 'MSRP' : 'list'
      } price`;
    }
  });
  return limitMessages;
}
