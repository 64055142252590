import { get, set, uniqBy, flatten, orderBy } from 'lodash';
import { getSyncDate } from 'client/site-modules/shared/components/profile/idm/idm-utils';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';

export const VIN_REFERENCE_PATH = 'alertData.vinReference';

const ALERT_TYPES = {
  PRICE_DROP: ['PRICE_DROP'],
  VIEWED_VIN_PRICE_DROP: ['VIEWED_VIN_PRICE_DROP'],
};

export const ALERT_TYPES_MAPPING = {
  ...ALERT_TYPES,
  ALL: flatten(Object.values(ALERT_TYPES)),
};

const PRICE_DROP_ALERTS = flatten([...ALERT_TYPES_MAPPING.PRICE_DROP, ...ALERT_TYPES_MAPPING.VIEWED_VIN_PRICE_DROP]);

const isUniqueAlert = alert => alert.alertType + get(alert, VIN_REFERENCE_PATH);

const isValidAlertType = (validAlertTypes, alertType) => validAlertTypes.some(type => type === alertType);

export const getAlertsPath = (type, vinAvailability) =>
  `data.alerts.type["${type}"].checkVinAvailability["${vinAvailability}"]`;

export const getNewAlertsCountStatePath = () => `profile.${getAlertsPath('ALL', true)}.newAlertsCount`;

/**
 * Filters alerts by provided alert type, sets alertViewedTs in case it's not set.
 * @param {Array} alerts - user alerts.
 * @param {Array} alertType - array of alert types = ALERT_TYPES_MAPPING.ALL
 * @returns {Object} IDM alert data to update
 */
export const markAlertAsViewed = (alerts = [], alertType = ALERT_TYPES_MAPPING.ALL) => {
  const alertsObj = { alerts: [] };
  const ts = getSyncDate();
  const alertsWithoutViewedTs = alerts
    .filter(alertObj => isValidAlertType(alertType, alertObj.alertType) && !alertObj.alertViewedTs)
    .map(alertObj => set({ alertData: { ...alertObj.alertData }, alertType: alertObj.alertType }, 'alertViewedTs', ts));
  if (alertsWithoutViewedTs.length) alertsObj.alerts = alertsWithoutViewedTs;

  return alertsObj;
};

/**
 *  Determines whether the alert is a VIEWED_VIN_PRICE_DROP.
 * @param {Array} alert - user alert
 */
export const isViewedVinPriceDrop = alert =>
  isValidAlertType(ALERT_TYPES_MAPPING.VIEWED_VIN_PRICE_DROP, alert.alertType);

/**
 * @param {Array} alerts - user alerts
 * @param {String} type - alert type
 * @returns {Array} unique IDM alerts
 */
export const getPriceDropAlerts = (alerts, type = 'PRICE_DROP') =>
  uniqBy(alerts.filter(alert => isValidAlertType(ALERT_TYPES_MAPPING[type], alert.alertType)), isUniqueAlert);

/**
 * Determines whether a VIEWED_VIN_PRICE_DROP is existing in PRICE_DROP
 * @param {Object} alert - user alert
 * @param {Array} priceDropAlertsVins - array of VINs from the PRICE_DROP alerts
 * @returns {Boolean}
 */
function isViewedVinPriceDropExist(alert, priceDropAlertsVins) {
  const vin = get(alert, VIN_REFERENCE_PATH);
  return isViewedVinPriceDrop(alert) && priceDropAlertsVins.includes(vin);
}

/**
 * Gets all alerts and filters:
 * 1) Alerts based off of the "alertType" passed in.
 * 2) Removes duplicated vins within it's own alert type
 * 3) Removes VIEWED_VIN_PRICE_DROP alertType if exists in PRICE_DROP alertType
 * @param {Array} alerts - user alerts.
 * @returns {Array} unique IDM alerts
 */
export const getAlerts = (alerts = [], alertType = ALERT_TYPES_MAPPING.ALL) => {
  const priceDropAlertsVins = getPriceDropAlerts(alerts).map(alert => get(alert, VIN_REFERENCE_PATH));
  return uniqBy(
    alerts.filter(
      alert => isValidAlertType(alertType, alert.alertType) && !isViewedVinPriceDropExist(alert, priceDropAlertsVins)
    ),
    isUniqueAlert
  );
};

/**
 * @param {Array} vins - vins with alerts.
 * @returns {Array} vins with new alerts
 */
export const getVinsWithNewAlerts = (vins = []) => vins.filter(vin => vin.hasNewAlert);

/**
 * Filters alerts by alertViewedTs and unique vinReference
 * @param {Array} alerts - user alerts.
 * @returns {Array} IDM alerts
 */
export const getUnViewedAlerts = (alerts = []) => uniqBy(alerts.filter(alert => !alert.alertViewedTs), isUniqueAlert);

/**
 * Filters by unique vinReference
 * @param {Array} alerts - user alerts.
 * @returns {Array} IDM alerts vins
 */
export const getAlertsVins = (alerts = []) =>
  alerts.filter(alert => get(alert, VIN_REFERENCE_PATH)).map(alert => get(alert, VIN_REFERENCE_PATH));

/**
 * Filters alerts by alertViewedTs and unique vinReference
 * @param {Array} alerts - user alerts.
 * @returns {Array} IDM alerts vins
 */
export const getUnViewedAlertsVins = (alerts = []) => getAlertsVins(getUnViewedAlerts(alerts));

/**
 * Filters all price drop alerts and sorted by unviewed first.
 * @param {Array} alerts
 */
export const getSortedValidPriceDrops = alerts =>
  [
    ...orderBy(alerts.filter(alert => !alert.alertViewedTs), alert => parseInt(alert.alertData.priceDrop, 10)),
    ...alerts.filter(alert => alert.alertViewedTs),
  ].filter(({ alertType }) => isValidAlertType(PRICE_DROP_ALERTS, alertType));

export const getTransparentPricing = (vin, isSubmittedLead) => {
  const {
    prices: { guaranteedPrice, displayPrice },
    type,
    vehicleInfo: {
      styleInfo: { make },
    },
  } = vin;
  const isNewVin = isNew(type);
  const isPartner = isPartnerDealer(vin);
  const hasGuaranteedPrice = !!(guaranteedPrice && guaranteedPrice > 0);
  const showDealerPrice =
    (isNewVin && isPartner && !['Honda', 'Acura'].includes(make) && hasGuaranteedPrice) || isSubmittedLead;
  return {
    transparentPrice: showDealerPrice && guaranteedPrice ? guaranteedPrice : displayPrice,
    transparentPriceLabel: showDealerPrice || !isNewVin ? 'Price' : 'MSRP',
  };
};
