import { set, omit, isEmpty, merge, get } from 'lodash';
import { getCurrentUser, signOut } from 'site-modules/shared/components/profile/firebase-auth';
import { signInAnonymously } from 'site-modules/shared/components/profile/firebase-anonymous-auth';
import { idmAPI, idmVanillaForumAPI } from 'client/data/api/api-client';
import { splitUserName } from 'site-modules/shared/utils/personal-info';
import { HTTP_NOT_FOUND } from 'client/utils/http-status';
import { PROVIDERS_MAPPING } from 'site-modules/shared/components/profile/auth-providers';
import { formatSyncAttributes } from 'site-modules/shared/components/profile/attributes/sync';
import { setAnalyticsData, setAnalyticsDataForSignIn, populateLastLandingPageVisitTs } from './idm-analytics';
import { getSyncDate, getSavableData } from './idm-utils';

const NO_CACHE_HEADERS = { Pragma: 'no-cache', 'Cache-Control': 'no-cache' };
const UPS_UPDATE_INTELLIGENT_MESSAGING_OMITS = ['prospectCreateTs', 'prospectEntryPoint', 'prospectEntryPointDetail'];
const SHOULD_POPULATE_PROSPECT_ENTRY = true;

/**
 * Get request options for all IDM requests
 * @param {Object} [body] - request body.
 * @param {String} [method] - request method.
 * @returns {Object} promise.
 */
export const getReqOptions = (body, method = 'GET') => {
  const headers = method === 'GET' ? NO_CACHE_HEADERS : {};
  return getCurrentUser()
    .then(currentUser => currentUser && currentUser.getIdToken())
    .then(token => ({ headers: { ...headers, authorization: token }, method, body: JSON.stringify(body) }));
};

/**
 * Set user name from first (recommended by firebase team) provider.
 * @param {Array} providers - array of firebase providers.
 * @param {Object} profile - destination object with user profile data.
 * @returns {Object} userProfile.
 */
export const setUserName = (providers, profile) => {
  const name = splitUserName(providers[0].displayName);

  if (name.first) {
    set(profile, 'person.firstName', name.first);
  }
  if (name.last) {
    set(profile, 'pii.lastName', name.last);
  }

  return profile;
};

/**
 * Set identifiers data (google or facebook UID, email) for IDM.
 * @param {Array} providers - firebase providers.
 * @param {Object} profile - destination object with user profile data.
 * @returns {Object} userProfile.
 */
export const setProviderData = (providers, profile) => {
  const userProfile = profile;
  if (providers) {
    providers.forEach(provider => {
      userProfile.identifiers[PROVIDERS_MAPPING[provider.providerId]] = [provider.uid];
    });
    if (!userProfile.identifiers.email) {
      userProfile.identifiers.email = [providers[0].email];
    }
    setUserName(providers, userProfile);
  }
  return userProfile;
};

/**
 * Common wrapper for IDM PUT method. Omits prospect details because this is an update call.
 * @param {Object} data - source object with user profile data.
 * @returns {Object} promise.
 */
export const update = data => {
  const userData = data;
  if (!isEmpty(userData.intelligentMessaging)) {
    userData.intelligentMessaging = omit(userData.intelligentMessaging, UPS_UPDATE_INTELLIGENT_MESSAGING_OMITS);
  }
  return getReqOptions(userData, 'PUT').then(options => idmAPI.fetchJson('/delta?isTransactional=true', options));
};

/**
 * Common wrapper for IDM PUT method (Only for Sign Up and Social Sign in)
 * @param {Object} data - source object with user profile data.
 * @returns {Object} promise.
 */
export const create = data =>
  getReqOptions(data, 'PUT').then(options => idmAPI.fetchJson('/?isTransactional=true', options));

/**
 * Common wrapper for IDM DELETE method (Only for Sign Up and Social Sign in)
 * @param {Object} data - source object with user profile data.
 * @returns {Object} promise.
 */
export const remove = () => getReqOptions(undefined, 'DELETE').then(options => idmAPI.fetchJson('/', options));

/**
 * Gets user profile from IDM
 * @returns {Object} promise.
 */
export const getProfile = () =>
  getReqOptions()
    .then(options => idmAPI.fetchJson('', options))
    .then(data => data.result);

/**
 * Generates vanilla_sso signature string for vanilla forums authentication
 * @returns {String} vanilla_sso signature string
 */
export const getVanillaSSOSignatureString = () =>
  getReqOptions()
    .then(options => idmVanillaForumAPI.fetchJson('/authtoken', options))
    .then(({ result: { ssoToken } }) => ssoToken);

/**
 * Common wrapper for IDM PUT method (only for Sign Up & Social Sign In)
 * Sends prospectCreateTs only if the user profile was not found in IDM (if IDM returns 404)
 * @param {Object} profile - source object with user profile data.
 * @returns {Object} promise.
 */
export const save = profile => {
  const userData = profile;
  return getProfile()
    .catch(({ status }) => {
      const is404error = status === HTTP_NOT_FOUND;
      if (is404error) {
        userData.intelligentMessaging.prospectCreateTs = getSyncDate();
      }
      return is404error;
    })
    .then(profileData => {
      const isNewProfile = isEmpty(profileData);
      if (isNewProfile) {
        userData.intelligentMessaging.prospectCreateTs = getSyncDate();
      }

      return isNewProfile;
    })
    .then(isNewProfile => (isNewProfile === true ? create : update)(userData));
};

export const updateLastLandingTs = () => update(merge(populateLastLandingPageVisitTs(), formatSyncAttributes()));

/**
 * Creates user profile in IDM (only for Sign Up & Social Sign In)
 * Sends prospectCreateTs only if the user profile was not found in IDM
 * @param {Object} personalInfo - source object with user personal info (firstName, lastName, email).
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export const createProfile = (personalInfo = {}, visitorAndPageContext) =>
  getCurrentUser()
    .then(user => {
      const profile = {
        identifiers: {},
        recordType: 'Aggregate',
      };
      if (personalInfo.email) {
        Object.assign(profile, {
          identifiers: {
            email: [personalInfo.email],
          },
        });
      } else {
        // Social sign in, google-one-tap case personalInfo.providerData
        const providerData = get(user, 'providerData.length') ? user.providerData : personalInfo.providerData;
        setProviderData(providerData, profile);
      }

      if (personalInfo.firstName) {
        Object.assign(profile, {
          person: {
            firstName: personalInfo.firstName,
          },
        });
      }
      if (personalInfo.lastName) {
        Object.assign(profile, {
          pii: {
            lastName: personalInfo.lastName,
          },
        });
      }

      setAnalyticsDataForSignIn(profile, visitorAndPageContext, SHOULD_POPULATE_PROSPECT_ENTRY);
      Object.assign(profile, getSavableData(personalInfo));

      return profile;
    })
    .then(profile => save(profile));

/**
 * Save or delete user data in IDM (lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @param {String} [method] - supported methods: UPDATE, DELETE.
 * @returns {Object} promise.
 */
export const updateProfileData = (data = {}, visitorAndPageContext, method) => {
  const userData = data;
  userData.recordType = method === 'DELETE' ? 'Delete' : 'Upsert';

  if (method !== 'DELETE') {
    setAnalyticsData(userData, visitorAndPageContext);
  } else {
    merge(userData, formatSyncAttributes());
  }

  return update(userData);
};

/**
 * Updates signed in users data in IDM(lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export const updateProfile = (data = {}, visitorAndPageContext) => {
  const userData = data;
  userData.recordType = 'Upsert';
  setAnalyticsDataForSignIn(userData, visitorAndPageContext);

  return update(userData);
};

/**
 * Creates anonymous user with data (lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export const createAnonymousProfile = (data = {}, visitorAndPageContext) => {
  const userData = data;
  userData.recordType = 'Upsert';
  setAnalyticsDataForSignIn(userData, visitorAndPageContext, SHOULD_POPULATE_PROSPECT_ENTRY);

  return create(userData);
};

/**
 * Signs out of current profile, signs in and creates anonymous user
 * @param {Object} data - Data to save for new anonymous user, sign in anonymously tracking data.
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export const signInAndCreateAnonymousProfile = ({ dataToCreate, signInAnonymouslyTracking }, visitorAndPageContext) =>
  signOut()
    .then(() => signInAnonymously(signInAnonymouslyTracking.creativeId, signInAnonymouslyTracking.trackingData))
    .then(() => createAnonymousProfile(dataToCreate, visitorAndPageContext));

/**
 * Updates to current profile, signs out of current profile, signs in and creates anonymous user
 * @param {Object} data - data to update for current user, data to save for new anonymous user, sign in anonymously tracking data.
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export const updateToCurrentThenSignInAndCreateAnonymousProfile = (
  { dataToUpdate, dataToCreate, signInAnonymouslyTracking },
  visitorAndPageContext
) =>
  updateProfileData(dataToUpdate, visitorAndPageContext, 'UPDATE')
    .then(() => signOut())
    .then(() => signInAnonymously(signInAnonymouslyTracking.creativeId, signInAnonymouslyTracking.trackingData))
    .then(() => createAnonymousProfile(dataToCreate, visitorAndPageContext));

/**
 * Save or user data in IDM in one request
 * @param {Object} deleteData - data to delete.
 * @param {Object} updateData - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export const mixedUpdateProfileData = ({ deleteData, updateData }, visitorAndPageContext) =>
  Promise.all([
    deleteData && updateProfileData(deleteData, {}, 'DELETE'),
    updateData && updateProfileData(updateData, visitorAndPageContext, 'UPDATE'),
  ]);
