import sumBy from 'lodash/fp/sumBy';
import maxBy from 'lodash/fp/maxBy';
import filter from 'lodash/fp/filter';

import { INCENTIVE_INPUT_TYPES, INCENTIVE_VALUES } from 'site-modules/shared/constants/inventory/price-analysis';
import { get, isFinite } from 'lodash';

import { getCashByTermsRange } from 'site-modules/shared/utils/car-buying/get-cash-by-terms-range';

const LEASE_COMBINABLE_LEVEL = 4;
const DEFAULT_ARRAY = [];

/**
 * Checks if subprogram is compatible with checked incentives.
 * @param {array} checkedIncentives
 * @param {number} subprogramId
 * @returns {boolean}
 */
export function isSubprogramCompatible(checkedIncentives, subprogramId) {
  return checkedIncentives.every(({ compatibleSubprogramIds = [] }) => compatibleSubprogramIds.includes(subprogramId));
}

/**
 * Returns sum of passed items by passed names.
 * @param {String} name - set value which invokes for each element in received array.
 * @return {Function} - new predefined function which sums objects using name's values.
 *
 * Example:
 * const f = getSumBy('age', 'age1');
 * f([{ age: 10 }, { age: 15 }, { age1: 20 }]) => 45;
 */
export const getSumBy = (...names) => sumBy(item => Number(item[names.find(name => item[name])]) || 0);

/**
 * Returns sum of passed incentives.
 * @param {object[]} incentives
 * @returns {number}
 */
export function getIncentivesSum(incentives) {
  return getSumBy(
    INCENTIVE_VALUES.AMOUNT,
    INCENTIVE_VALUES.REBATE,
    INCENTIVE_VALUES.CASH,
    INCENTIVE_VALUES.TAX_REBATE_AMOUNT
  )(incentives);
}

/**
 * Merges primary incentives with checked and removes some of them if it's have incompatible subprogram.
 * @param {object[]} checkedIncentives
 * @param {object[]} primaryIncentives
 * @returns {{appliedIncentives: object[], primaryIncentiveAmount: number, conditionalIncentiveAmount: number}}
 */
export function mergePrimaryWithChecked(checkedIncentives = DEFAULT_ARRAY, primaryIncentives = DEFAULT_ARRAY) {
  const filteredPrimaryIncentives = checkedIncentives.length
    ? primaryIncentives.filter(
        ({ subprogramId, primary }) => primary && isSubprogramCompatible(checkedIncentives, subprogramId)
      )
    : primaryIncentives;

  return {
    primaryIncentiveAmount: getIncentivesSum(filteredPrimaryIncentives),
    conditionalIncentiveAmount: getIncentivesSum(checkedIncentives),
    appliedIncentives: [...filteredPrimaryIncentives, ...checkedIncentives],
    checkedIncentives,
  };
}

/**
 * @param field
 * @returns {function} - Curried function
 */
export const filterBy = field =>
  /**
   * @param value
   * @returns {function} - Filtered function
   *
   * Example:
   * const collection = [{ type: 'checkbox'}, { type: 'radio'}];
   * filterBy('type')('checkbox')(collection) => [{ type: 'checkbox'}];
   */
  value => filter(item => item[field] === value);

/**
 * @param {Array} arr - Bonus and Other incentives
 * @returns {Array} - filtered incentives of both collections by the primary field equal to false
 */
export const getConditionalIncentives = arr => arr.filter(({ primary }) => !primary);

/**
 * @param {String} name - set value which invokes for each element in received array.
 * @return {Function} - new predefined function which picks a max value of received collection by name.
 *
 * Example:
 * const f = getMaxBy('age');
 * f([{ age: 10 }, { age: 15 }]) => { age: 15 };
 */
export const getMaxBy = name => maxBy(name);

/**
 * @param {Number} msrp - displayPrice
 * @param {Number} docFee - docFee
 * @param {Number} dealerPrice - guaranteedPrice
 * @param {Number} sum - sum of primary incentives
 * @returns {boolean} - should show primary incentives with conditional.
 */
export const showPrimaryWithConditionalIncentives = (msrp, docFee, dealerPrice, sum) =>
  docFee + dealerPrice + sum > msrp;

/**
 * @param {Boolean} isPrimaryInConditional
 * @param {Number} maxPurchaseAmount
 * @param {Number} maxConditionalAmount
 * @returns {Number} - max amount of primary and conditional incentives
 */
export function getMaxConditionalAmount(isPrimaryInConditional, maxPurchaseAmount, maxConditionalAmount) {
  return isPrimaryInConditional ? Math.max(maxPurchaseAmount, maxConditionalAmount) : maxConditionalAmount;
}

/**
 * Filter out lease incentives.
 * @param {object[]} incentives
 */
export function filterOutLeaseIncentives(incentives) {
  return incentives.filter(({ combinableLevel }) => combinableLevel !== LEASE_COMBINABLE_LEVEL);
}

/**
 * Filter out primary incentives.
 * @param {object[]} incentives
 */
export function filterOutPrimaryIncentives(incentives) {
  return incentives.filter(({ primary }) => primary === false);
}

/**
 * Filter out financial incentives.
 * @param {object[]} incentives
 */
export function filterOutFinancialIncentives(incentives) {
  return incentives.filter(({ type }) => type !== INCENTIVE_INPUT_TYPES.RADIO);
}

/**
 * @param field - field by which arrays are filtered
 * @returns {function} - a function accepting a initial Array and search Array
 */
export const replaceBy = field => (array, searchArray) =>
  /**
   * @param array - Each value of an initial array is compared with all values of search array by field.
   * @param searchArray - If a value of search Array is equal to Initial value it's included in a new collection,
   * else an initial value comes back.
   * @returns {Array} - new collection of replaced values.
   */
  array.map(value => searchArray.find(searchValue => value[field] === searchValue[field]) || value);

/**
 * Returns true/false whenever some of passed checkedIncentives eq. passed incentiveId.
 * @param {array} checkedIncentives
 * @param {number} incentiveId
 * @returns {boolean}
 */
export function isIncentiveChecked(checkedIncentives, incentiveId) {
  return checkedIncentives.some(({ id }) => id === incentiveId);
}

export function getDealerPrice(inventory) {
  const guaranteedPrice = get(inventory, 'computedDisplayInfo.transparentPricingCompliance.guaranteedPrice');
  const docFee = get(inventory, 'prices.docFee');
  const hasDocFee = isFinite(docFee) && docFee >= 0;
  return hasDocFee ? guaranteedPrice + docFee : guaranteedPrice;
}

export function filterOutNoCacheIncentives(incentives = []) {
  const incentivesWithTermRangeCache = incentives.map(incentive => ({
    id: incentive.incentiveId,
    ...incentive,
    cash: getCashByTermsRange(incentive),
  }));

  return incentivesWithTermRangeCache.filter(incentive => incentive.cash !== 0);
}
